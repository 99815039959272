import { Box, Card, CardMedia } from "@mui/material";
import "../../assets/styles/Card.css";
import { useSelector, useDispatch } from "react-redux";
import { toggleCardSide } from "../../features/ui/uiSlice";
import { S3_URL } from "../../utils/constants";
import { setSelectedCard } from "../../features/cards/cardsSlice";
import { useSwipeable } from "react-swipeable";

const CardDetailImage = ({ card }) => {
  const isCardFrontSide = useSelector((state) => state.uiState.isCardFrontSide);
  const dispatch = useDispatch();
  const nextCards = useSelector((state) => state.cardsState.nextCards);

  const handleDoubleTap = () => {
    dispatch(toggleCardSide());
  };

  const handleChangeCard = (card) => {
    if (!card) {
      return;
    }
    if (!isCardFrontSide) {
      dispatch(toggleCardSide());
    }
    dispatch(setSelectedCard(card));
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleChangeCard(nextCards.nextCard);
    },
    onSwipedRight: () => {
      handleChangeCard(nextCards.previousCard);
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <Card
      {...handlers}
      onDoubleClick={handleDoubleTap}
      sx={{
        my: 2,
        height: "600px",
        maxWidth: "380px",
        backgroundImage: "none",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        WebkitTapHighlightColor: "transparent",
      }}
      className="card-container"
    >
      <Box
        sx={{ width: "100%", height: "90%" }}
        className={`card ${isCardFrontSide ? "" : "flipped"}`}
      >
        <CardMedia
          component="img"
          image={S3_URL + card.img_card}
          alt="Card Image"
          className="card-front-side"
          sx={{ objectFit: "fill" }}
        />
        <CardMedia
          component="img"
          image={S3_URL + card.img_card_back}
          alt="Card Image"
          className="card-back-side"
          sx={{ objectFit: "fill" }}
        />
      </Box>
    </Card>
  );
};

export default CardDetailImage;

import { Box } from "@mui/material";
import {
  CardDetailModal,
  CardsGrid,
  DeckSelector,
  FilterComponent,
  SearchBar,
} from "../components";
import { useSelector } from "react-redux";
import {
  CARD_TYPES,
  userCardsQuery,
  userDecksQuery,
  userSampleCardsQuery,
} from "../utils";
import { redirect } from "react-router-dom";
import {
  setAllCards,
  setPurchasedDecks,
  setSelectedDeck,
  setSelectedCard,
  setFilterToCards,
} from "../features/cards/cardsSlice";
import { deckTypes, DECK_PURCHASE_CARD_TITLE, fetchImage } from "../utils";
import { openCard, closeCard } from "../features/ui/uiSlice";

export const loader =
  (store, queryClient) =>
  async ({ request }) => {
    const params = Object.fromEntries([
      ...new URL(request.url).searchParams.entries(),
    ]);
    if (params.deck && CARD_TYPES.includes(params.deck)) {
      const deckTitle = DECK_PURCHASE_CARD_TITLE[params.deck];
      store.dispatch(setSelectedDeck(deckTitle));
    }

    // In HomePage.js loader
    // Add a check for the referrer or a specific query parameter
    const isFromRedeemPage = request.headers
      .get("Referer")
      ?.includes("/redeem-codes");
    if (isFromRedeemPage) {
      // Force refetch data instead of using cached data
      await queryClient.refetchQueries(["userCards", userId]);
      await queryClient.refetchQueries(["userDecks", userId]);
      await queryClient.refetchQueries(["userSampleCards", userId]);
    }

    
    const user = store.getState().userState.user;
    if (!user) {
      return redirect("/signin");
    }
    const userId = user.user_id;
    let cardList = [];
    let purchasedDecks = [];

    try {
      const userCardsResponse = await queryClient.ensureQueryData(
        userCardsQuery(userId, "EN")
      );
      const userDecksResponse = await queryClient.ensureQueryData(
        userDecksQuery(userId, "EN")
      );

      const userCardsSampleResponse = await queryClient.ensureQueryData(
        userSampleCardsQuery(userId, "EN")
      );

      const userCards = userCardsResponse.cards;
      const userDecks = userDecksResponse.userDecks;
      const exampleCards = userCardsSampleResponse.samUserCards;

      const purchasedDeckCodes = new Set(
        userDecks.map((deck) => deck.deck_code)
      );
      const filteredUserCards = userCards.filter((card) =>
        purchasedDeckCodes.has(card.card_deck_code)
      );
      const filteredExampleCards = exampleCards.filter(
        (card) => !purchasedDeckCodes.has(card.card_deck_code)
      );
      const resultCards = [...filteredUserCards, ...filteredExampleCards];
      const uniqueResultCards = Array.from(
        new Map(resultCards.map((card) => [card.card_id, card])).values()
      );

      purchasedDecks = userDecksResponse.userDecks;
      cardList = uniqueResultCards;
    } catch (error) {
      console.error(error);
      return {};
    }
    store.dispatch(closeCard());
    store.dispatch(setPurchasedDecks(purchasedDecks));
    store.dispatch(setAllCards(cardList));
    const activeFilter = store.getState().cardsState.areCardsFiltered;
    if (activeFilter !== "not filtered") {
      console.log("active filter", activeFilter);
      store.dispatch(setFilterToCards(activeFilter));
    }

    // Random Card
    const url = new URL(request.url);
    const queryParams = new URLSearchParams(url.search);
    const shuffleCard = queryParams?.get("shuffleCard");
    if (!!shuffleCard) {
      store.dispatch(openCard());
      let actualDeck = JSON.parse(localStorage.getItem("decks")).actualDeck[0]
        .card_deck_code;
      const matchingCards = cardList.filter(
        (card) => card.card_deck_code === actualDeck
      );
      const randomCard =
        matchingCards[Math.floor(Math.random() * matchingCards.length)];
      store.dispatch(setSelectedCard(randomCard));
    }
    return null;
  };

const HomePage = () => {
  const { isSearchBarOpen } = useSelector((state) => state.uiState);
  const selectedDeck = useSelector((state) => state.cardsState.selectedDeck);
  const purchasedDecksCodes = useSelector(
    (state) => state.cardsState.purchasedDecks
  );
  const deckProperties = deckTypes?.filter(
    (deck) => deck.title === selectedDeck
  )?.[0];
  const selectedDeckCode = Object.keys(DECK_PURCHASE_CARD_TITLE).find(
    (key) => DECK_PURCHASE_CARD_TITLE[key] === selectedDeck
  );
  const isShowMore = !purchasedDecksCodes?.includes(selectedDeckCode);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px 20px",
        position: "relative",
      }}
      className="homePage"
    >
      <FilterComponent />

      {isSearchBarOpen && <SearchBar />}
      <DeckSelector deckTypes={deckTypes} />
      <CardsGrid
        deckProperties={deckProperties}
        isShowMore={isShowMore}
        deckCode={selectedDeckCode}
      />
      <CardDetailModal deckProperties={deckProperties} />
    </Box>
  );
};

export default HomePage;

import { useNavigate } from "react-router-dom";
import { Typography, Box, Link } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import AppleIcon from "@mui/icons-material/Apple";
import {
  getAllUsers,
  registerSocialUser,
  getUserByGoogleId,
  signInGoogle,
} from "../logic/Requests";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { loginUser } from "../features/user/userSlice";
import { CarouselButton, SnackbarComponent } from "../components";
import { useState } from "react";
import { uiConstants } from "../utils";
import { resetUiState } from "../features/ui/uiSlice";

const SignUpPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [error, setError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleGoogleSuccess = async (response) => {
    const { credential } = response;
    try {
      const decodedToken = jwtDecode(credential);
      const userExists = await checkIfUserExists(decodedToken.email);

      if (userExists) {
        const googleUser = await getUserByGoogleId(credential);
        if (+googleUser.status.code === 0) {
          const loginResult = await signInGoogle(credential);
          const { token, user_id, first_name, last_name, email } = loginResult;
          dispatch(loginUser({ token, user_id, first_name, last_name, email }));
          return navigate("/app/home");
        }
        throw new Error("Email already registered with other system");
      }
      const registrationResult = await registerSocialUser(credential, "google");
      const { token, user_id, first_name, last_name, email } = registrationResult.user;
      dispatch(loginUser({ token, user_id, first_name, last_name, email }));
      navigate("/app/home");
    } catch (error) {
      console.error("Google Sign-Up Error:", error);
      setError(
        error.message ||  "Registration failed. Please try again."
      );
      setOpenSnackbar(true);
    }
  };

  const checkIfUserExists = async (email) => {
    const users = await getAllUsers();
    return users.users.some((user) => user.email === email);
  };

  const handleGoogleFailure = (error) => {
    console.error("Google Sign-Up Failure:", error);
  };

  return (
    <Box
      className="container"
      sx={{
        backgroundColor: "#1E1E2C",
        color: "white",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding:"10px 30px 30px"
      }}
    >
      <SnackbarComponent
          openSnackbar={openSnackbar}
          handleCloseSnackbar={() => setOpenSnackbar(false)}
          error={error} />
      <Box
        className="signup-content"
        sx={{ width: "100%", maxWidth: "400px", textAlign: "center" }}
      >
        <div>
          <Typography
            variant="h4"
            className="title"
            sx={{ marginBottom: "20px" }}
          >
            Sign Up for Virtues Cards
          </Typography>
          <Typography
            variant="body1"
            className="subtitle"
            sx={{ marginBottom: "20px" }}
          >
            Save, store, and sync your decks, journal entries, and bookmarked
            cards across multiple devices.
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={handleGoogleFailure}
            text="signup_with" // This prop customizes the button to show "Sign up with Google"
            size="large"
            shape="pill"
            
          />
          <CarouselButton
            onClick={() => {
              navigate("/email-signup");
            }}
            backGroundColor={uiConstants.PURPLE}
            text={"Sign up with email"}
            topMargin={"20px"}
            bottomMargin={"40px"}
            textColor={"white"}
            hoverColor={"#d81b60"}
            borderR={"18.2px"}
          />
          <Typography
            variant="body2"
            className="login-link"
            sx={{ marginBottom: "60px" }}
          >
            Already have an account?{" "}
            <Link
              className="link"
              component="button"
              variant="body2"
              onClick={() => navigate("/signin")}
              sx={{ color: "#e91e63", textTransform: "none" }}
            >
              Log in
            </Link>
          </Typography>
          <Typography variant="body2" className="terms" sx={{ color: "#888" }}>
            By continuing, you agree to Virtues Matter's{" "}
            <Link
              href="/terms-and-policy?type=terms"
              variant="body2"
              sx={{ color: "white", textTransform: "none" }}
            >
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link
              href="/terms-and-policy?type=policy"
              variant="body2"
              sx={{ color: "white", textTransform: "none" }}
            >
              Privacy Policy
            </Link>
            .
          </Typography>
        </div>
      </Box>
    </Box>
  );
};

export default SignUpPage;
import React from "react";
import { Box} from "@mui/material";
import { JournalPencil, JournalEmpty, JournalEntriesList } from "../../components";
import { useNavigate, useLoaderData } from "react-router-dom";
import { getEntries } from "../../utils";
import { setUserEntries } from "../../features/journal/journalSlice";

export const loader = ( store, queryClient) => async () => {
  const user = store.getState().userState.user;
  const entries = await getEntries(user.user_id);
  store.dispatch(setUserEntries(entries.journals))
  return { entries: entries.journals }
} 

const JournalPage = () => {
  const { entries } = useLoaderData();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/app/journal/entries/new-entry?newEntry=true");
  }
  return (
    <Box
      sx={{
        backgroundColor: "white",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px 20px 110px",
        color: "black",
        position: "relative",
        overflow: "auto",
      }}
    >
      <JournalPencil handleClick={handleClick}/>
      {entries.length === 0 ? <JournalEmpty /> : 
        <JournalEntriesList entries={entries} />
      }

    </Box>
  );
};

export default JournalPage;

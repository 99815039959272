import React, { useRef } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedDeck } from "../../features/cards/cardsSlice";
import DeckSelectorItem from "./DeckSelectorItem";
import "../../assets/styles/Home.css";

const DeckSelector = ({ deckTypes }) => {
  const dispatch = useDispatch();
  const selectedDeck = useSelector((state) => state.cardsState.selectedDeck);
  const itemRefs = useRef([]);

  const handleDeckTypeClick = (deckType) => {
    const container = document.getElementsByClassName(
      "deck-selector-container"
    )[0];
    const targetIndex = deckTypes.findIndex((d) => d.title === deckType.title);
    const target = itemRefs.current[targetIndex];

    if (container && target) {
      container.scrollLeft = target.offsetLeft - container.offsetLeft;
      dispatch(setSelectedDeck(deckType.title));
    }
    let deckscards =  document.getElementById('deckscards')
    deckscards.scrollTo({
      top: 0,
      behavior: 'smooth' 
    })
  };

  return (
    <Box
      className="deck-selector-container"
      sx={{
        display: "flex",
        overflowX: "auto",
        marginBottom: "10px",
        padding: "0 20px",
        width: "100%",
        height: "110px",
        paddingBottom: "10px",
        marginTop: "10px",
        scrollBehavior: "smooth",
      }}
    >
      {deckTypes.map((deckType, index) => (
        <DeckSelectorItem
          key={deckType.title}
          deckType={deckType}
          selectedDeck={selectedDeck}
          ref={(el) => (itemRefs.current[index] = el)}
          handleDeckTypeClick={handleDeckTypeClick}
        />
      ))}
    </Box>
  );
};

export default DeckSelector;

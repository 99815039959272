import { Box, CardContent } from "@mui/material";
import { FaHeart } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import CheckOutPreviewItem from "../shop/CheckOutPreviewItem";

const CardGridItem = ({
  deckProperties,
  card,
  handleCardClick,
  cardsLength,
  index,
  isCompactCards
}) => {
  return (
    <Box
      key={card.card_id}
      sx={{
        cursor: "pointer",
        position: "relative",
        minHeight: isCompactCards ? "90px" : "30px",
        maxHeight: isCompactCards ? "90px" : "30px",
        color: "black",
        display: "flex",
        alignItems: "flex-start",
        paddingX: "10px",
        borderBottom:
          index !== cardsLength - 1 ? "3px solid whiteSmoke" : "none",
        transition: "all 0.3s ease",
        marginBottom: "1rem"
      }}
      onClick={() => handleCardClick(card)}
    >
      <CheckOutPreviewItem
        card={card}
        cardImage={
          "../../assets/images" + card.img_card + process.env.REACT_APP_IMAGES_VERSION
        }
        cardImageBack={
          "../../assets/images" + card.img_card_back + process.env.REACT_APP_IMAGES_VERSION
        }
        textColor="#003865"
        showBack={true}
        isHome={true}
      />

      {+card.is_fave === 1 ? (
        <CardContent
          sx={{
            position: "absolute",
            top: 0,
            right: 4,
            color: "red",
            padding: 0
          }}
        >
          <FaHeart style={{ fontSize: isCompactCards ?  "1rem" : "0.8rem" }} />
        </CardContent>
      ) : (
        ""
      )}

      {card.is_read === 1 ? (
        <CardContent
          sx={{
            position: "absolute",
            padding: 0,
            right: isCompactCards ? 32 : 20,
            color: "green",
          }}
        >
          <FaCheckCircle style={{ fontSize: isCompactCards ?  "1rem" : "0.8rem" }} />
        </CardContent>
      ) : (
        ""
      )}
    </Box>
  );
};

export default CardGridItem;

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import GlobalStyle from "./assets/styles/globalStyles";
import "./assets/styles/fontawesome";
import "./assets/styles/fonts.css";
import "./assets/styles/global.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { store } from "./store";
import { Provider } from "react-redux";
import { register as registerServiceWorker } from './utils/serviceworker';

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <GoogleOAuthProvider clientId="552595586243-ah622lrsbvdrpl0tr97eb3ljgivefbks.apps.googleusercontent.com">
        <GlobalStyle />
        <App />
      </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>
);

// Register the service worker
registerServiceWorker();
import { useEffect } from "react";
import CarouselComponent from "../components/CarouselComponent";

const IntroPage = () => {
  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");
    if (hasVisited) {
      window.location.href = "/signup";
    }
  }, []);

  return <CarouselComponent />;
};

export default IntroPage;

import { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  TextField,
  Button,
  Link,
  Snackbar,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../logic/Requests";
import MuiAlert from "@mui/material/Alert";
import { uiConstants } from "../utils/constants";


const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleBack = () => {
    navigate(-1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await forgotPassword(email);
      if (+response.code !== 0) {
        setError(
          response.description ?? "Invalid email or password. Please try again."
        );
        setOpenSnackbar(true);
        return;
      } else{
        navigate("/forgot-password-success");
      }
    } catch (error) {
      setError(error.message || "Invalid email. Please try again.");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const textFieldStyles = {
    "& .MuiInput-underline:before": { borderBottomColor: "white" },
    "& .MuiInput-underline:after": { borderBottomColor: "white" },
    "& .MuiInputBase-input": { color: "white" },
    "& .MuiInputLabel-root": { color: "white" },
    width: "100%",
    maxWidth: "100%",
  };

  return (
    <Box
      className="container"
      sx={{
        backgroundColor: "#1E1E2C",
        color: "white",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "10px 30px 30px",
      }}
    >
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
                <MuiAlert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </MuiAlert>
      </Snackbar>
      <AppBar
        position="static"
        sx={{
          boxShadow: "none",
          backgroundColor: "transparent",
          paddingBottom: "30px",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Forgot Password
          </Typography>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          width: "100%",
          maxWidth: "400px",
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        <Typography
          variant="body1"
          sx={{ marginBottom: "30px", letterSpacing: "0.1px" }}
        >
          Please enter the email address you signed up with, and we will send
          you a new password.
        </Typography>
        <Typography
          variant="body1"
          sx={{ marginBottom: "40px", letterSpacing: "0.1px" }}
        >
          If you don’t remember which email address you used, email{" "}
          <Link
            href="mailto:service@virtuesmatter.org"
            sx={{ color: "white", textDecoration: "underline" }}
          >
            service@virtuesmatter.org
          </Link>
          .
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            type="email"
            label="Email Address"
            variant="standard"
            className="input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            fullWidth
            margin="normal"
            sx={textFieldStyles}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="button"
            fullWidth
            sx={{
              width: "25%",
              borderRadius: "20px",
              backgroundColor: uiConstants.PURPLE, // Pink color
              color: "white",
              "&:hover": {
                backgroundColor: "#d81b60", // Darker pink on hover
              },
              marginBottom: "30px",
              marginTop: "20px",
              textTransform: "none",
              alignSelf: "flex-end",
            }}
          >
            Reset
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPasswordPage;

import { Box, Typography } from "@mui/material";
import CheckOutBulletListItem from "./CheckOutBulletListItem";

function CheckOutPrice({ description, price }) {
  return (
    <Box
    sx={{
      textAlign: "left",
      padding: "0 20px",
      marginRight: "30px",
      marginTop: "20px",
    }}
  >
    <Typography
      variant="h6"
      sx={{
        backgroundColor: "#90EE90",
        maxWidth: "90px",
        padding: "5px 10px",
        borderRadius: "16px",
        fontSize: "14px",
        margin: "0 -15px 20px auto"
      }}
    >
      ${price} USD
    </Typography>

    { description.map((item, index) => 
      <CheckOutBulletListItem key={index} description={item} />)}
  </Box>
  )
}

export default CheckOutPrice
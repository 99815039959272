import React, { useState, useEffect} from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useLocation } from "react-router-dom";
import vp_logo from "../../assets/images/vp_cards.png"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { logOutUser } from "../../features/user/userSlice";
import backgroundImage from "../../assets/images/placeholders/Title.png"
import ShopImage from "../../assets/images/shopImage.png"
import CheckOutImage from "../../assets/images/CheckOutCardTitleImage.png"
import { navBarObjects } from "../../utils";

const TopAppBarWithMenu = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userState.user);
  const { pathname } = useLocation();
  const shortPathname = pathname.split("/")[2]
  const background = [
    {
      image: backgroundImage,
      size: "440px",
      pathName: "home",
    }, 
    {
      image: CheckOutImage,
      size: "720px",
      pathName: "shop",
    },
    { 
      image: CheckOutImage,
      size: "720px",
      pathName: "shop-checkout",
    }
  ]

  const actualObject = navBarObjects.find((obj) => pathname.includes(obj.pathName)) ?? {title: "Virtues Cards"};
  const menuConfig = background.find((item) => item.pathName === shortPathname) ?? { image: backgroundImage, size: "440px" };
    
  

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };


  const logOut = () => {
    dispatch(logOutUser());
    navigate("/signin");
    window.location.reload();
  };


  //PWA

  const [isReadyForInstall, setIsReadyForInstall] = React.useState(false);

  async function downloadApp() {
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    const result = await promptEvent.userChoice;
    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    // Hide the install button.
    setIsReadyForInstall(false);
  }



  const menuItems = [
    { text: "Account", onClick: () => { navigate("/app/account") }, icon: "user" },
    { text: "About Us", onClick: () => { navigate("/app/learn-more/about-us") }, icon: "info-circle" },
    { text: "Resources", onClick: () => { navigate("/app/learn-more/resources") }, icon: "folder" },
    { text: "Redeem Code", onClick: () => { navigate("/app/learn-more/redeem-codes") }, icon: "gift" },
  ];

  const menuItemsComplete = [
    ...menuItems,
    { text: "Download App", onClick: downloadApp, icon: "download" },
  ]

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile.
      // event.preventDefault();
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container.
      setIsReadyForInstall(true);
    });
  }, []);



  return (
    <Box sx={{ position: "sticky", zIndex: 10}}>
      <AppBar
        sx={{
          position: "sticky",
          top: 0,
          width: '100%',
          maxWidth: "400px",
          height: "55px",
          margin: "0 auto",
          borderRadius: "0 0 1rem 1rem",
          background: `url(${menuConfig.image})`, 
          backgroundSize: menuConfig.size, 
          backgroundRepeat: "no-repeat", 
          backgroundPosition: "center", 
          overflow: "hidden",
          '&::before': {
            content: '""',
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "rgba(0, 0, 0, 0.15)", // Adjust color and opacity as needed
            zIndex: -1, // Ensure it is behind the content
            borderRadius: "inherit", // Match the border radius of the AppBar
          },
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1,  fontSize: "1.6rem", translate: "-10px 0px" }}>
            {actualObject.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          sx={{
            width: 310,
            justifyContent: "flex-start",
            background: "white",
            padding: 0,
          }}
          className="container"
        >
          <div
            style={{
              textAlign: "center",
              width: "100%",
              padding: 0,
              height: "120px",
            }}
            className="container"
          >
            <img
              src={vp_logo}
              alt="Virtues Cards Logo"
              style={{ width: "210px", marginBottom: "10px" }}
            />
            <Typography
              variant="body1"
              style={{
                textAlign: "right",
                width: "100%",
                paddingTop: 10,
                paddingRight: 50,
              }}
            >
              by Virtues Matter
            </Typography>
          </div>
          <Divider />
          <div style={{ width: "100%" }}>
            <Typography
              variant="h6"
              style={{ color: "#0B3B60", paddingTop: 20, width: "1005" }}
            >
              Hello {user?.first_name}
            </Typography>

            <List style={{ width: "100%", marginTop: 20, paddingLeft: 20 }}>
              {(isReadyForInstall ? menuItemsComplete : menuItems).map((item, index) => {
                return (
                <ListItem button key={index} onClick={item.onClick}>
                  <FontAwesomeIcon icon={item.icon} color="#0B3B60" size="1x" />
                  <ListItemText
                    primary={item.text}
                    style={{
                      color: "#0B3B60",
                      textAlign: "left",
                      marginLeft: 10,
                    }}
                  />
                </ListItem>
              )})}
            </List>
          </div>
          <Box sx={{ flexGrow: 1 }} />
          <List style={{ width: "100%", paddingLeft: 20 }}>
            <ListItem button onClick={logOut}>
              <FontAwesomeIcon icon="sign-out" color="#0B3B60" size="1x" />
              <ListItemText
                primary="Log Out"
                style={{
                  color: "#0B3B60",
                  textAlign: "left",
                  marginLeft: 10,
                }}
              />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default TopAppBarWithMenu;
import React from 'react'
import { RxCross2 } from "react-icons/rx";
import { Box, Typography } from '@mui/material';
import  CheckOutPreviewItem  from "./CheckOutPreviewItem"
import { uiConstants } from '../../utils';

const CheckOutPreviewList = ({  showingPreview, handleShowPreview, deckCards, }) => {
  return (
    <Box
    className="preview-container"
    sx={{
      position: "absolute",
      bottom: !showingPreview ? "-61%" : "90px",
      width: "100%",
      backgroundColor: uiConstants.PURPLE,
      transition: "all 0.3s ease-out",
      color: "white",
      height: "80%",
      borderRadius: "60px 60px 0 0",
      
      '@media (max-height: 980px and  min-height: 769px)': {
        bottom: !showingPreview ? "-65%" : "90px",
      },
              // Media queries
      '@media (max-height: 768px)': {
        bottom: !showingPreview ? "-55%" : "90px",
      },
      '@media (max-height: 600px)': {
        bottom: !showingPreview ? "-44%" : "90px",
      }
    }}
  >
    {showingPreview && (
      <RxCross2
        size={30}
        onClick={handleShowPreview}
        style={{ position: "absolute", top: "20px", right: "50px" }}
      />
    )}

    <Typography
      variant="h6"
      onClick={handleShowPreview}
      sx={{
        display: "inline-block",
        marginTop: "15px",
      }}
    >
      Preview Cards
    </Typography>

    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        overflow: "auto",
        height: "100%",
        padding: "0px 0 80px 0",
        marginTop: "20px",
        borderRadius: "40px 40px 0 0",
        scrollbarWidth: "none",
      }}
    >
      {deckCards.map((card, index) => (
        <Box sx={{ padding: "20px", width: "100%", height: "100%" }}>
        <CheckOutPreviewItem
          key={card.card_id + card.card_code + index}
          card={card}
        />
        </Box>
      ))}
    </Box>
  </Box>
  )
}

export default CheckOutPreviewList
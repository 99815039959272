import { useState } from "react";
import { Box, Typography, Skeleton, CircularProgress } from "@mui/material";
import { useNavigation } from "react-router-dom";

function ShopItem({  deck, completeDecks}) {
  const nagivation = useNavigation();
  const [imageLoaded, setImageLoaded] = useState(false);
  const isDecaDeck = deck.name === "DECA"

  
  if (nagivation.state === "loading") {
    return  <Skeleton variant="rectangular" width={132} height={240} />
  }

  let actualDeck = completeDecks[deck.code]
  if (actualDeck === undefined) return null
  
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "240px", cursor: "pointer"  }}>
      <Typography
        className="shop-card-subtitle"
        sx={{
          color: "#2b3a80",
        //   fontWeight: "bold",
          fontSize: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "50px",
          letterSpacing: "0.1px",
          textAlign: "center",
          transform: "Scale(1.2, 1.2)",
          fontWeight: "bolder"
        }}
      >
        {deck.name} Cards
      </Typography>
      <div className="shop-card-stack">
        <div className="shop-card-image shop-card1">
        
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
              animation="wave"
              sx={{ bgcolor: 'grey.600', borderRadius: '16px', transition: 'all 0.3s ease', opacity:(!imageLoaded ?  1 : 0 ) }}
              
              style={{ position: "absolute", top: 0, left: 0, zIndex: 2 }}
            />

            { !imageLoaded && <CircularProgress sx={{position: "absolute", top: "50%", left: "50%", translate: "-50% -50%", zIndex: 3, color: "white"}}/>}
        
          <img
            src={(isDecaDeck ? actualDeck?.[2]?.img_card + process.env.REACT_APP_IMAGES_VERSION : actualDeck?.[0]?.img_card + process.env.REACT_APP_IMAGES_VERSION) ?? '/logo.webp'}
            alt={deck.title}
            className="shop-card-image-main"
            data-title={deck.title}
            onLoad={() => setImageLoaded(true)}
            loading="lazy" 
          />
        </div>
        <img
          src={actualDeck?.[1]?.img_card  + process.env.REACT_APP_IMAGES_VERSION ?? '/imageholder.webp'}
          alt={deck.title}
          className="shop-card-image shop-card2"
          loading="lazy" 
        />
        <img
          src={actualDeck?.[2]?.img_card + process.env.REACT_APP_IMAGES_VERSION ?? '/imageholder.webp'}
          alt={deck.title}
          className="shop-card-image shop-card3"
          loading="lazy" 
        />
      </div>
    </Box>
  );
}

export default ShopItem;

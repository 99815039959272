import React from "react";
import ShopItem from "./ShopItem";
import { Grid, Typography } from "@mui/material";
import { uiConstants } from "../../utils";

function ShopGrid({deck, title, onClickFn, completeDecks}) {
    if (deck.length === 0) return null
  return (
    <>
      <Typography
        className="shop-card-subtitle"
        sx={{
          color: uiConstants.PURPLE,
          fontSize: "24px",
          fontWeight: 900,
          height: "50px",
          letterSpacing: "0.1px",
          alignSelf: "flex-start",
          marginBottom: "24px",
          paddingLeft: "10px",
        }}
      >
        { title }
      </Typography>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={8}
        sx={{ paddingInline: "16px" }}
        marginBottom={"64px"}
      >
        {deck.map((deck) => {
          return (
          <Grid item xs={6} key={deck.name} onClick={() =>onClickFn(deck)}>
            <ShopItem deck={deck} completeDecks={completeDecks} />
          </Grid>
        )})}
      </Grid>
    </>
  );
}

export default ShopGrid;

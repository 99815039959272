import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function JournalEntry({ entry }) {
  const navigate = useNavigate();
  const parser = new DOMParser();
  const doc = parser.parseFromString(entry.journal_entry, "text/html");
  const htmlContentWithLineBreaks = { __html: doc.body.innerHTML };
  const timestamp = entry.when_updated ? entry.when_updated : entry.when_added;
  const date = new Date(timestamp * 1000);
  const options = { hour: "2-digit", minute: "2-digit", hour12: true };
  const onClick = () => {
    navigate(`/app/journal/entries/${entry.journal_id}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: entry.background_color,
        color: "black",
        padding: "16px",
        borderRadius: "10px",
        cursor: "pointer",
        height: "190px",
        width: "95%",
        border: "1px solid smoke",
        boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.4)",
      }}
      onClick={onClick}
    >
      <Typography
        variant="h6"
        component="div"
        textAlign={"left"}
        fontWeight={"bold"}
      >
        {entry.card.title}
      </Typography>
      <Box sx={{ flexGrow: 1, marginTop: "8px" }}>
        <div
          style={{
            fontSize: "14px",
            textAlign: "left",
            fontFamily: "sans-serif",
            fontWeight: "lighter",
            height: "90px",
            maxHeight: "90px",
            overflow: "hidden",
          }}
          dangerouslySetInnerHTML={htmlContentWithLineBreaks}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "16px",
        }}
      >
        <Typography variant="caption" sx={{ color: "gray" }}>{date.toLocaleDateString()}</Typography>
        <Typography variant="caption" sx={{ color: "gray" }}>
          {date.toLocaleTimeString([], options)}
        </Typography>
      </Box>
    </Box>
  );
}

export default JournalEntry;

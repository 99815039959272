import { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Tab,
  Tabs,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TermsText} from "../../utils/texts/termsAndPolicy";
import { PrivacyPolicy } from "../../utils/texts/policyText";
import theme from "../../assets/theme/tabTheme"
import { ThemeProvider } from '@mui/material/styles';


const TermsOfServicePage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(type === "terms" ? 0 : 1)
    },
   [type]);
  

  const handleBack = () => {
    navigate(-1);
  };

  const handleChange = (newValue) => {
    navigate(`/terms-and-policy?type=${newValue === 0 ? "terms" : "privacy"}`);
  };
  

  return (
    <ThemeProvider theme={theme}>
    <Box className="page-container">
      <AppBar
        position="static"
        className="page-section"
        sx={{
          boxShadow: "none",
          backgroundColor: "#2b3a80",
          color: "white",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Terms of Service
          </Typography>
        </Toolbar>
      </AppBar>

      <Tabs
        value={value}
        sx={{
          marginBottom: "0px",
          width: "100%",
          '& .MuiTab-root': { color: "#bdbdccd6" }
        }}
      >
        <Tab sx={{ width: "50%" }} label="TERMS OF SERVICE" onClick={() => handleChange(0)} />
        <Tab sx={{ width: "50%" }} label="PRIVACY POLICY" onClick={() => handleChange(1)}/>
      </Tabs>

      <Box  sx={{ width: "100%", maxWidth: "600px", textAlign: "left", overflow: "auto" }}>
        {value === 0 ? < TermsText /> : <PrivacyPolicy />}
      </Box>
    </Box>
    </ThemeProvider>
  );
};

export { TermsOfServicePage } ;

import React, { useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import GlobalStyle from "./assets/styles/globalStyles";
import router from "./router";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "../src/utils";
import {
  Backdrop,
  CircularProgress,
  Typography,
} from "@mui/material";
import { sendUrlsToServiceWorker } from "./utils/serviceworker"; // Import the function to communicate with Service Worker

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cachingProgress, setCachingProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    const checkAuthStatus = () => {
      const isSignedIn = localStorage.getItem("isSignedIn") === "true";
      if (isSignedIn) {
        setIsAuthenticated(true);
      }
    };

    checkAuthStatus();
  }, []);

  useEffect(() => {
    const cachePages = async () => {
      if ("serviceWorker" in navigator && navigator.serviceWorker.controller && !isOffline) {
        const pagesToCache = ["/app/home"]; 
        
        setLoading(true);
        try {
          sendUrlsToServiceWorker(pagesToCache); 
        } catch (error) {
          console.error("Failed to send URLs to Service Worker:", error);
          setLoading(false);
        }
      }
    };

    if (isAuthenticated) {
      cachePages();
    }
  }, [isAuthenticated, isOffline]);

  useEffect(() => {
    if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event.data.type === "CACHE_PROGRESS") {
          setCachingProgress(parseFloat(event.data.progress));
          if (parseFloat(event.data.progress) >= 100) {
            setLoading(false);
          }
        }
      });
    }
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <GlobalStyle />
        <div className="container" style={{ padding: 0, background: "white" }}>
          <RouterProvider router={router} />
        </div>
      </QueryClientProvider>
    </>
  );
};

export default App;
import { Box, Typography } from "@mui/material";
import { VirtuesBtn } from "../../components";

const AboutUsPage = () => {
  return (
    <Box
      sx={{
        padding: "20px 0 100px 0",
        fontSize: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
      className="text-container"
    >
      <Typography variant="body1" sx={{ fontSize: "16px" }}>
        The Virtues Cards App is your fast pass to a life of joy, meaning, and
        purpose. This tool is designed to aid you in the everyday quest to
        acquire and cultivate virtues—qualities such as compassion, gratitude,
        strength, hope, resilience, and justice. Like a book of daily readings,
        it guides you to develop upright character and ultimately to become your
        best self.
      </Typography>

      <Typography
        variant="h3"
        sx={{ fontWeight: "bold", fontSize: "18px", marginTop: "20px" }}
      >
        How to Use the Virtues Cards app
      </Typography>
      <Typography variant="body1">
        The app can be used to help you make decisions, strengthen
        relationships, or simply provide a positive focus for your day. Parents
        can use the app to raise children of compassion and integrity. Educators
        can reference the app to create a safe and caring space for learning.
        Civic and business leaders can integrate the virtues to encourage
        excellence, ethical conduct, and a culture of recognition in the
        workplace.
      </Typography>

      <Box sx={{ textAlign: "center", margin: "20px 0 20px" }}>
        <VirtuesBtn
          text={"More Tips"}
          size={"small"}
          btnType={"button"}
          onClickFn={() =>
            (window.location.href = "https://www.virtuesmatter.org/app")
          }
        />
      </Box>
    </Box>
  );
};

export default AboutUsPage;

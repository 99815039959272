import { Button } from "@mui/material"

const CarouselButton = ( {onClick, startIcon, className, backGroundColor, text, bottomMargin, topMargin, textColor, hoverColor, borderR}) => {
  return (
    <Button
    onClick={onClick}
    variant="contained"
    startIcon={startIcon ? startIcon : ""}
    className={`button ${className}`}
    fullWidth
    sx={{
      marginTop: topMargin ?? "0px",
      marginBottom: bottomMargin ?? "0px",
      backgroundColor: backGroundColor,
      color: textColor ?? "#000",
      "&:hover": { backgroundColor: hoverColor ?? "#e0e0e0" },
      textTransform: "none",
      width: "218px",
      paddingInline: "12px",
      borderRadius: borderR ?? "5px",
    }}
  >
    { text }
  </Button>
  )
}

export default CarouselButton
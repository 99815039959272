import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { closeCardSelector } from "../../features/journal/journalSlice";
import { uiConstants } from "../../utils";
import ClearIcon from "@mui/icons-material/Clear";

const JournalCardSelector = ({ cardOptions, setCardType }) => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(
    (state) => state.journalState.cardSelectorOpen
  );

  const handleClose = () => {
    dispatch(closeCardSelector());
  };

  const handleClick = (card) => {
    setCardType(card);
    dispatch(closeCardSelector());
  };
  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <DialogContent sx={{ width: "100%", height: "100vh", margin: 0 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            maxWidth: "400px",
            mx: "auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              backgroundColor: "white",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "200px",
                position: "sticky",
                top: 0,
                backgroundColor: uiConstants.DARK_PURPLE,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px 10px 0 0",
              }}
            >
              <Typography
                sx={{ color: "white", textAlign: "center", fontSize: "18px" }}
              >
                Select a card
              </Typography>
              <Box
                sx={{
                  right: "16px",
                  top: "18px",
                  position: "absolute",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                <ClearIcon sx={{ fontSize: "20px", color: "white" }} />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                overflow: "auto",
                width: "100%",
                paddingLeft: "15px",
              }}
            >
              {cardOptions.map((card) => (
                <Typography
                  key={card.card_id}
                  onClick={() => handleClick(card)}
                  sx={{
                    cursor: "pointer",
                    display: "inline-block",
                    width: "100%",
                    textAlign: "left",
                    padding: "10px",
                    borderBottom: "0.5px solid gray",
                  }}
                  fontWeight={"light"}
                  fontSize={"14px"}
                  color={"gray"}
                >
                  {card.title}
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default JournalCardSelector;

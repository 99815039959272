import React from "react";
import { FaAngleDown } from "react-icons/fa6";
import { Box, Typography } from "@mui/material";

const CreateEntryHeader = ({ handleOpen, cardType }) => {
  return (
    <Box
      onClick={handleOpen}
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        fontSize: "20px",
        cursor: "pointer",
        paddingInline: "16px",
      }}
    >
      <Typography sx={{ marginRight: "6px", fontSize: "20px" }}>
        Card:
      </Typography>
      <FaAngleDown style={{ marginRight: "12px" }} />
      <Typography
        sx={{ fontSize: "20px", color: "gray" }}
        className="entryJournalHeader"
      >
        {cardType.title}
      </Typography>
    </Box>
  );
};

export default CreateEntryHeader;

import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: [] }],
      ["clean"],
    ],
  };
  
  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "header",
    "font",
    "blockquote",
    "bullet",
    "align",
    "list",
  ];

const EntryTextEditor = ({ content, handleContentChange}) => {
  return (
    <ReactQuill
    value={content}
    onChange={handleContentChange}
    modules={modules}
    formats={formats}
    theme="snow"
    style={{
      display: "flex",
      flexDirection: "column-reverse",
    }}
  />
  )
}

export default EntryTextEditor
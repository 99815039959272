import React from 'react'
import { Box, Typography } from '@mui/material';

const EntryHeader = ({  cardType}) => {
  return (
    <Box
    sx={{
      display: "flex",
      width: "100%",
      alignItems: "center",
      fontSize: "20px",
      paddingInline: "16px",
      
    }}
  >
    <Typography sx={{ fontSize: "20px"}}>{cardType.title}</Typography>
  </Box>
  )
}

export default EntryHeader
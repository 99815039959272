import axios from "axios";
import { API_URL } from "../../utils/constants";


const localApiUrl = "http://localhost:5000/api";

export const checkoutPayment = async ({priceId, userId, deckCode}) => {

  const requestBody = {
    priceId,
    userId, 
    deckCode
  };

  try {
    const response = await axios.post(
      `${API_URL}/stripe/create-checkout-session`,
      requestBody, 
    {
        "Content-Type": 'application/json'
      }
    );
    const { url } = response.data
    window.location.href = url
  } catch (error) {
    console.error(error);
    throw error.response.data;
  }
};

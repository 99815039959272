import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useCallback, useEffect, useState } from "react";
import { verifyRecaptcha } from "../../utils";
import { CircularProgress, Box } from "@mui/material";

const ReCaptchaComponent = ({ btnComponent }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    setIsLoading(true);

    if (!executeRecaptcha) {
      setIsLoading(false);
      return;
    }

    try {
      const token = await executeRecaptcha("reCaptchaVerification");
      const response = await verifyRecaptcha(token);

      if (!response.success) {
        setIsLoading(false);
        return alert("Captcha verification failed. Please try again.");
      } else {
        setCaptchaVerified(true);
        setIsLoading(false);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"  }}>
        <CircularProgress sx={{ color: "white" }} />
        <p style={{ color: "white" }}>Verifying you're human</p>
      </Box>
    );
  }
  if (!captchaVerified) {
    return (
      <div
        style={{
          cursor: "pointer",
          border: "1px solid white",
          width: "100px",
          padding: "5px",
          borderRadius: "5px",
          margin: "auto",
        }}
        onClick={handleReCaptchaVerify}
      >
        {" "}
        Revalidate 
      </div>
    );
  }
  return <>{btnComponent}</>;
};

export default ReCaptchaComponent;

import { createSlice } from "@reduxjs/toolkit";
import {
  filterByDeck,
  filterCardsByTitle,
  // getDecksName,
  getDeckFromTitle,
  getNextcards,
} from "../../utils";

const defaultState = {
  actualDeck: [],
  actualDeckComplete: [],
  allcards: [],
  allDecks: [],
  userDecks: [
    "Virtues Reflection Cards",
    "Virtues Resilience Cards",
    "Virtues Family Cards",
    "Virtues Education Cards",
    "Virtues Character Cards",
    "Virtues DECA Cards",
  ],
  selectedDeck: "Virtues Reflection Cards",
  selectedCard: {},
  nextCards: { randomCard: {}, previousCard: {}, nextCard: {} },
  areCardsFiltered: "not filtered",
  searchParam: "",
  purchasedDecks: [],
  completeDecks: {
    REF: [],
    SUN: [],
    FAM: [],
    EDU: [],
    CHA: [],
    DEC: [],
  },
};

const getDecksfromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("decks")) || defaultState;
};

const cardsSlice = createSlice({
  name: "cardsState",
  initialState: getDecksfromLocalStorage,
  reducers: {
    updateByDeck: (state, cards) => {
      const cardsFilteredByDeck = filterByDeck(cards);
      const deckFromTitle = getDeckFromTitle(
        cardsFilteredByDeck,
        state.selectedDeck
      );
      return deckFromTitle;
    },

    setPurchasedDecks: (state, action) => {
      if (!action.payload) {
        state.purchasedDecks = [];
        return;
      }
      const deckCodes = action.payload.map((deck) => deck.deck_code);
      state.purchasedDecks = deckCodes;
      localStorage.setItem("decks", JSON.stringify(state));
    },

    setAllCards: (state, action) => {
      state.allcards = action.payload;
      state.allDecks = filterByDeck(state.allcards);
      state.actualDeck = cardsSlice.caseReducers.updateByDeck(
        state,
        state.allcards
      );
      state.actualDeckComplete = cardsSlice.caseReducers.updateByDeck(
        state,
        state.allcards
      );
      localStorage.setItem("decks", JSON.stringify(state));
    },

    setSelectedDeck: (state, action) => {
      state.selectedDeck = action.payload;
      state.actualDeck = cardsSlice.caseReducers.updateByDeck(
        state,
        state.allcards
      );
      state.actualDeckComplete = cardsSlice.caseReducers.updateByDeck(
        state,
        state.allcards
      );
      // filter the cards if a filter is currently applied
      if (state.areCardsFiltered !== "not filtered" && state.areCardsFiltered) {
        cardsSlice.caseReducers.setFilterToCards(state, {
          payload: state.areCardsFiltered,
        });
      }

      localStorage.setItem("decks", JSON.stringify(state));
      return;
    },

    setSelectedCard: (state, action) => {
      state.selectedCard = action.payload;
      const actualCard = state.selectedCard;
      const cards = state.actualDeck;
      state.nextCards = getNextcards(actualCard, cards);
      localStorage.setItem("decks", JSON.stringify(state));
    },

    setFilterToCards: (state, action) => {
      if (state.searchParam !== "") {
        state.searchParam = "";
      }

      const filteredBy = action.payload;
      state.areCardsFiltered = filteredBy;
      let actualCards;
      if (filteredBy === "not filtered") {
        state.actualDeck = cardsSlice.caseReducers.updateByDeck(
          state,
          state.actualDeckComplete
        );
        localStorage.setItem("decks", JSON.stringify(state));
        return;
      } else {
        actualCards = state.actualDeckComplete.filter(
          (card) => card[filteredBy] === 1
        );
      }

      state.actualDeck = actualCards;
      localStorage.setItem("decks", JSON.stringify(state));
    },

    setSearchParam: (state, action) => {
      if (state.filteredBy !== "not filtered") {
        state.filteredBy = "not filtered";
      }
      state.searchParam = action.payload;
      const filteredCards = filterCardsByTitle(
        state.actualDeckComplete,
        state.searchParam
      );

      state.actualDeck = filteredCards;
      localStorage.setItem("decks", JSON.stringify(state));
    },

    updateCardStatus: (state, action) => {
      const { user_card_id, is_fave, is_read } = action.payload;
      state.allcards = state.allcards.map((card) => {
        if (card.user_card_id === +user_card_id) {
          return { ...card, is_fave, is_read };
        }
        return card;
      });
      state.actualDeck = cardsSlice.caseReducers.updateByDeck(
        state,
        state.allcards
      );
      state.actualDeckComplete = cardsSlice.caseReducers.updateByDeck(
        state,
        state.allcards
      );
      localStorage.setItem("decks", JSON.stringify(state));
    },

    setCompleteDecks: (state, action) => {
      let actualDecks = {};
      if (state.completeDecks) {
        actualDecks = state.completeDecks;
        actualDecks[action.payload.deck_code] = action.payload.cards;
      } else {
        actualDecks[action.payload.deck_code] = action.payload.cards;
      }

      state.completeDecks = actualDecks;
      localStorage.setItem("decks", JSON.stringify(state));
    },
  },
});

export const {
  setAllCards,
  setCompleteDecks,
  setFilterToCards,
  setPurchasedDecks,
  setSearchParam,
  setSelectedCard,
  setSelectedDeck,
  updateByDeck,
  updateCardStatus,
} = cardsSlice.actions;

export default cardsSlice.reducer;

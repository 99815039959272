import { createBrowserRouter } from "react-router-dom";
import { store } from "./store";
import { queryClient } from "../src/utils";
import {
  AboutUsPage,
  AccountPage,
  CheckoutPage,
  EditEntryPage,
  EmailSignUpPage,
  ForgotPasswordPage,
  ForgotPasswordSuccessPage,
  HomePage,
  IntroPage,
  JournalPage,
  LearnMoreLayOut,
  LoginPage,
  ProtectedRoute,
  RedeemCodesPage,
  ResetPasswordPage,
  ResourcesPage,
  ShopPage,
  SignInPage,
  SignUpPage,
  TutorialPage,
} from "./pages"
import { TermsOfServicePage } from "./pages/terms-and-policy/TermsOfServicePage";
import { loader as homePageLoader } from "./pages/HomePage";
import { loader as authPageLoader } from "./pages/LoginPage";
import { loader as checkOutLoader } from "./pages/shop/CheckoutPage"
import { loader as shopLoader } from "./pages/shop/ShopPage"
import { loader as journalLoader } from "./pages/journal/JournalPage"
import { loader as editEntryLoader } from "./pages/journal/EditEntryPage"




const router = createBrowserRouter([
  {
    path: "/",
    element: <IntroPage />,
  },
  {
    path: "/signup",
    element: <SignUpPage />,
    loader: authPageLoader(store),
  },
  {
    path: "/signin",
    element: <SignInPage />,
    loader: authPageLoader(store),
  },
  {
    path: "/email-signup",
    element: <EmailSignUpPage />,
    loader: authPageLoader(store),
  },
  {
    path: "/login",
    element: <LoginPage />,
    loader: authPageLoader(store),
  },
  {
    path: "/app",
    element: <ProtectedRoute />,
    children: [
      {
        path: "home",
        element: <HomePage />,
        loader: homePageLoader(store, queryClient),
      },
      {
        path: "shop",
        element: <ShopPage/>,
        loader: shopLoader(store, queryClient),
      },
      {
        path: "shop-checkout/:deckCode",
        element: <CheckoutPage />,
        loader: checkOutLoader(),
      },
      {
        path: "journal",
        element: <JournalPage />,
        loader: journalLoader(store, queryClient),
      },      {
        path: "journal/entries/:entryId",
        element: <EditEntryPage />,
        loader: editEntryLoader(store, queryClient),
      },
      {
        path: "reminders",
        element: <div>REMINDERS</div>,
      },
      {

        path: "Account",
        element: <AccountPage />,
      },
      {
        path: "reset-password",
        element: <ResetPasswordPage />,
	  },
      {
        path: "learn-more",
        element: <LearnMoreLayOut />,
        children: [
          {
            path: "about-us",
            element: <AboutUsPage />,
          },
          {
            path: "tutorial",
            element: <TutorialPage />,
          },
          {
            path: "resources",
            element: <ResourcesPage />,
          },
          {
            path: "redeem-codes",
            element: <RedeemCodesPage />,
          }
        ],
      },
    ],
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
    loader: authPageLoader(store),
  },
  {
    path: "/forgot-password-success",
    element: <ForgotPasswordSuccessPage />,
    loader: authPageLoader(store),
  },
  {
    path: "/terms-and-policy",
    element: <TermsOfServicePage />,
  },
]);

export default router;

import { useState, useEffect } from 'react'
import { Box, Typography, Skeleton, CircularProgress } from '@mui/material';
import { useSelector } from "react-redux";
import '../../assets/styles/fonts.css'; 
import { S3_URL } from "../../utils/constants";

function CheckOutPreviewItem({  card, cardImage, cardImageBack, textColor, showBack, isHome = false }) {

  const [imageLoaded, setImageLoaded] = useState(false);
  const [cardImageFront, setCardImage] = useState(cardImage ? cardImage : card.img_card + process.env.REACT_APP_IMAGES_VERSION);
  const [cardBack, setCardImageBack] = useState(null);
  const { isListCards } = useSelector((state) => state.uiState);
  const { isCompactCards } = useSelector((state) => state.uiState);
  const isGridView = isHome ? isCompactCards : true;

  useEffect(() => {
    const controller = new AbortController();

    const fetchImages = async () => {
      try {
        const promises = [];
        

        if (showBack && !cardBack) {
          promises.push(
            fetch(cardImageBack ? cardImageBack : card.img_card_back + process.env.REACT_APP_IMAGES_VERSION, { signal: controller.signal })
              .then(response => response.blob())
              .then(image2 => URL.createObjectURL(image2))
              .then(imageUrl2 => setCardImageBack(imageUrl2))
          );
        }

        // Esperar todas las promesas
        await Promise.all(promises);
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error(error);
        }
      }
    };

    fetchImages();

    return () => {
      controller.abort(); 
    };

  }, [card.img_card, card.img_card_back, cardImage, cardImageBack, showBack, cardBack]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "flex-start", gap: "10px"}} >
      <Box sx={{ position: "relative", borderRadius: isGridView ? "10px" : "0px" }}>
        <Skeleton
          variant="rectangular"
          width={isGridView ? "70" :"10"}
          height={isGridView ? "80" : "10"}
          animation="wave"
          sx={{ bgcolor: 'grey.600', borderRadius: isGridView ? "10px" : "0px", transition: "all 0.4s ease-out", opacity: (!imageLoaded ? 1 : 0) }}
          style={{ position: "absolute", top: 0, left: 0, zIndex: 2 }}
        />
        { !imageLoaded && <CircularProgress sx={{position: "absolute", top: "50%", left: "50%", translate: "-50% -50%", zIndex: 3, color: "white"}}/>}

        <Box sx={{ display: isGridView ? '' : 'none', width: isGridView ? "80px" : "30px", height: isGridView ? "80px" : "50px", position: "relative" }}>
          <Box sx={{position: "absolute", top: 0, left: 0, zIndex: 1}}>
            <img 
              src={card.img_card.includes(S3_URL) ? card.img_card : S3_URL + card.img_card} 
              alt={card.title} 
              width={isGridView ? "70" : "30"} 
              height={isGridView ? "80" : "50"} 
              onLoad={() => setImageLoaded(true)} 
              style={{ borderRadius: isGridView ? "10px" : "0px", width: isGridView ? "70" : "30", height: isGridView ? "80" : "50" }} 
              loading="lazy"
            />
          </Box>

          { showBack && cardBack && 
            <Box sx={{position: "absolute", top: 0, left: 0, zIndex: 0}}>
              <img 
                src={card.img_card_back.includes(S3_URL) ? card.img_card_back : S3_URL + card.img_card_back} 
                alt={card.title} 
                width={isGridView ? "70" : "30"} 
                height={isGridView ? "80" : "50"} 
                onLoad={() => setImageLoaded(true)} 
                style={{ borderRadius: isGridView ? "10px" : "0px", width: isGridView ? "70" : "30", height: isGridView ? "80" : "50" }} 
                loading="lazy"
              />
            </Box>
          }
        </Box>
      </Box>

      <Box sx={{ flexBasis: "100%" }}>
        <Typography sx={{ marginTop: "0px", textAlign: "left", color: textColor, fontSize: "17px", fontWeight: 900 }}>
          {card.title}
        </Typography>
    
        <Typography sx={{ display: isGridView ? '' : 'none', marginTop: "0px", fontSize: "12px", textAlign: "left", fontWeight: "200", color: textColor , lineHeight: 1 }}>
          {card.description.slice(0, 144) + "..."}
        </Typography>
      </Box>
    </Box>
  );
}

export default CheckOutPreviewItem;
import { useState } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { uiConstants } from "../utils/constants";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { updatePassword, signIn } from "../logic/Requests";
import { openSnackbar } from "../features/ui/uiSlice";

const ResetPasswordPage = () => {
    const dispatch = useDispatch()
    const [ actualPassword, setActualPassword ] = useState("")
    const [ newPass1, setNewPass1 ] = useState("")
    const [ newPass2, setNewPass2 ] = useState("")
    const isDisabled = (newPass1 === newPass2) && (newPass1.length > 5)
    const [showPassword, setShowPassword] = useState(false);
    const { user_id, email } = useSelector(state => state.userState.user)

    const handleResetPassword = async () => {

      try {
        const response = await signIn(email, actualPassword);
        if (+response.status.code !== 0) {
          dispatch(openSnackbar({message: "Invalid current password. Please try again.", type:"error"}))
          return;
      }} catch (error) {
        dispatch(openSnackbar("Something went wrong. Please try again."))
        return;
      }

      try {
        await updatePassword({userId: user_id, updValue:newPass1})
        dispatch(openSnackbar({message: "Password Updated!", type:"success"}))
        setActualPassword("")
        setNewPass1("")
        setNewPass2("")
      } catch (error) {
        console.error(error)
      }
    }
  
    const handleTogglePasswordVisibility = () => (
        setShowPassword((showPassword) => !showPassword)
    )
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "20px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "400px",
          minWidth: "300px",
          textAlign: "center",
          padding: "0 20px",
          marginTop: "30px",
        }}
      >

        <TextField
          label="Current Password"
          variant="outlined"
          value={actualPassword}
          onChange={(e) => setActualPassword(e.target.value)}
          fullWidth
          required
          margin="normal"
        />
         <Box
          className="password-container"
          sx={{ position: "relative",  width: "100%" }}
        >
          <TextField
            label="New Password"
            variant="outlined"
            value={newPass1}
            type={showPassword ? "text" : "password"}
            onChange={(e) => setNewPass1(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          <IconButton
            onClick={handleTogglePasswordVisibility}
            sx={{
              position: "absolute",
              right: "0",
              top: "30%",
              color: "black",
            }}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
          </Box>
        <TextField
          label="Confirm New Password"
          variant="outlined"
          value={newPass2}
          type={showPassword ? "text" : "password"}
          onChange={(e) => setNewPass2(e.target.value)}
          fullWidth
          required
          margin="normal"
        />

        <Button
          variant="contained"
          onClick={handleResetPassword}
          disabled={!isDisabled}
          sx={{
            backgroundColor: uiConstants.PURPLE,
            color: "white",
            textTransform: "none",
            borderRadius: "20px",
            width: "218px",
            marginTop: "10px",
            "&:hover": { backgroundColor: uiConstants.DARK_PURPLE, },
          }}
        >
          Reset Password
        </Button>
      </Box>
    </Box>
  );
}

export default ResetPasswordPage
import { Button } from "@mui/material"
import { IoCartOutline } from "react-icons/io5"
import { uiConstants } from "../../utils"

function CheckOutBtn({ handleSubmit}) {
  return (
    <Button
    variant="contained"
    onClick={handleSubmit}
    sx={{
      backgroundColor: uiConstants.PURPLE,
      color: "white",
      padding: "10px 20px",
      borderRadius: "20px",
      marginTop: "20px",
      "&:hover": {
              backgroundColor: uiConstants.DARK_PURPLE, // Darker pink on hover
            },
    }}
  >
    <IoCartOutline size={25} style={{ marginRight: "10px" }} />
    Buy deck
  </Button>
  )
}

export default CheckOutBtn
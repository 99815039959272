import { getUserDecks } from "../../logic/Requests";
import {
  getAllCards,
  getUserCards,
  getUserSampleCards,
} from "../../utils/requests/cards";

export const userCardsQuery = (userId, language) => {
  return {
    queryKey: ["userCards", userId, language],
    queryFn: () => getUserCards(userId, "EN"),
  };
};
export const userSampleCardsQuery = (userId, language) => {
  return {
    queryKey: ["userSampleCards", userId, language],
    queryFn: () => getUserSampleCards(userId, "EN"),
  };
};

export const userDecksQuery = (userId, language) => {
  return {
    queryKey: ["userDecks", userId, language],
    queryFn: () => getUserDecks(userId, "EN"),
  };
};

export const allCardsByDeckQuery = (deckType, language) => {
  return {
    queryKey: ["allCardsByDeck", deckType, language],
    queryFn: () => getAllCards(deckType, language),
  };
};

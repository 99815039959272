import { Box, IconButton } from "@mui/material";
import ShuffleIcon from '@mui/icons-material/Shuffle';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ThreeSixtySharpIcon from '@mui/icons-material/ThreeSixtySharp';
import { useDispatch, useSelector } from "react-redux";
import { toggleCardSide } from "../../features/ui/uiSlice";
import { setSelectedCard } from "../../features/cards/cardsSlice";


const CardDetailBottomBar = ({ next, previous, random }) => {
  const dispatch = useDispatch();
  const isCardFrontSide = useSelector((state) => state.uiState.isCardFrontSide);


  const handleShuffle = () => {
    dispatch(toggleCardSide());
  };

  const handleClick = (card) => {
    if (!card) {
      return;
    }
    if (!isCardFrontSide){
      dispatch(toggleCardSide());
    }
    dispatch(setSelectedCard(card));
  };
  return (
    <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      bgcolor: "transparent",
      color: "white",
      py: 1,
      width: '100%',
      maxWidth: 400,
    }}
  >
    <IconButton color="inherit" aria-label="shuffle" onClick={() => handleClick(random)}>
      <ShuffleIcon />
    </IconButton>
    <IconButton color="inherit" aria-label="previous" disabled={!previous} onClick={() => handleClick(previous)}>
      <NavigateBeforeIcon />
    </IconButton>
    <IconButton color="inherit" aria-label="next" onClick={handleShuffle}>
      <ThreeSixtySharpIcon />
    </IconButton>
    <IconButton color="inherit" aria-label="next" disabled={!next} onClick={() => handleClick(next)}>
      <NavigateNextIcon />
    </IconButton>
  </Box>
  )
}

export default CardDetailBottomBar
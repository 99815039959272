import { createGlobalStyle } from "styled-components";
import './fonts.css';

const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    box-sizing: border-box;
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    font-family: '"HelveticaNeue-Light"';
  }

  * {
    box-sizing: inherit;
  }
`;

export default GlobalStyle;

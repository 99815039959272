import React from "react";
import { Box } from "@mui/material";
import JournalEntry from "./JournalEntry";
function JournalEntriesList({ entries }) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        paddingY: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {entries.map((entry) => (
        <JournalEntry key={entry.journal_id} entry={entry} />
      ))}
    </Box>
  );
}

export default JournalEntriesList;

import axios from "axios";
import { API_URL } from "../../utils/constants";

export const redeemCode = async (email, code) => {

    const requestBody = {
      email,
      code,
    }
    try {
      const response = await axios.post(`${API_URL}/user/redeemCode`, requestBody);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error.response.data;
    }
  };
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { uiConstants } from "../utils/constants";

const ForgotPasswordSuccessPage = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/login");
  };

  const handleDone = () => {
    navigate("/login");
  };

  return (
    <Box
      className="container"
      sx={{
        backgroundColor: "#1E1E2C",
        color: "white",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "10px 30px 30px",
      }}
    >
      <AppBar position="static" sx={{ boxShadow: "none", backgroundColor: "transparent", paddingBottom: "30px" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Forgot Password
          </Typography>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          width: "100%",
          maxWidth: "400px",
          textAlign: "center",
          marginTop: "20px"
        }}
      >
        <Typography variant="body1" sx={{ marginBottom: "30px", letterSpacing: "0.1px", }}>
          Your password has been successfully changed and sent to your email. Please check your email and use your temporary password to login.
        </Typography>
        <Button
          variant="contained"
          onClick={handleDone}
          fullWidth
          sx={{
            backgroundColor: uiConstants.PURPLE,
            color: "white",
            textTransform: "none",
            "&:hover": { backgroundColor: "#d81b60" },
            borderRadius: "20px",
            marginTop: "20px",
            marginBottom: "40px",
            alignSelf: "flex-end",
            width: "25%",
          }}
        >
          Done
        </Button>
      </Box>
    </Box>
  );
};

export default ForgotPasswordSuccessPage;
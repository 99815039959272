import React from "react";
import { Box } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../assets/styles/Journal.css";
import {
  addEntry,
  editEntry,
  uiConstants,
  JOURNAL_DIALOG_TEXT,
} from "../../utils";
import { useLoaderData } from "react-router-dom";
import { JournalCardSelector } from "../../components";
import { redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openCardSelector } from "../../features/journal/journalSlice";
import { useNavigate } from "react-router-dom";
import CreateEntryHeader from "../../components/journal/CreateEntryHeader";
import EntryHeader from "../../components/journal/EntryHeader";
import EntryTextEditor from "../../components/journal/EntryTextEditor";
import AlertDialog from "../../components/journal/JournalDialog";

export const loader =
  (store, queryClient) =>
  async ({ request }) => {
    const url = new URL(request.url);
    const params = Object.fromEntries(url.searchParams.entries());
    const path = url.pathname;
    const user = store.getState().userState.user ?? {};
    const userEntries = store.getState().journalState.userEntries ?? [];
    let selectedEntry = undefined;
    if (!params.newEntry) {
      selectedEntry = userEntries.find(
        (entry) => entry.journal_id === path.split("/")[4]
      );
      if (!selectedEntry) {
        return redirect("/app/journal");
      }
    }
    const cards = store.getState().cardsState.allcards;
    // const cardOptions = cards.map((card) => { return  {card_id:card.card_id, title: card.title}})
    const cardOptions = cards
      .reduce((acc, card) => {
        if (
          card.title !== "DECA Cards" &&
          !acc.find((c) => c.title === card.title)
        ) {
          acc.push({ card_id: card.card_id, title: card.title });
        }
        return acc;
      }, [])
      .sort((a, b) => a.title.localeCompare(b.title));
    return { cardOptions, newEntry: params.newEntry, selectedEntry, user };
  };

const EditEntryPage = () => {
  const { cardOptions, newEntry, selectedEntry, user } = useLoaderData();
  const colors = [
    "#E5E5EA",
    "#5995f7",
    "#ea8f95",
    "#8eeda6",
    "#e9ed8e",
    "#ed8ee8",
  ];
  const generalReflection = { title: "General Reflection", card_id: 1 };
  const navigate = useNavigate();
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [dialogText, setDialogText] = React.useState("");
  const [cardType, setCardType] = React.useState(
    selectedEntry?.card ?? generalReflection
  );
  const [content, setContent] = React.useState(
    selectedEntry?.journal_entry ?? ""
  );
  const [color, setColor] = React.useState(
    selectedEntry?.background_color ?? colors[0]
  );
  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(openCardSelector());
  };

  const handleContentChange = (value) => {
    setContent(value);
  };
  const handleCancel = () => {
    setDialogText(JOURNAL_DIALOG_TEXT.discard);
    setDialogOpen(true);
  };

  const handleDeleteEntryClick = () => {
    setDialogText(JOURNAL_DIALOG_TEXT.delete);
    setDialogOpen(true);
  };

  const createNewEntry = async () => {
    try {
      const response = await addEntry({
        userId: user.user_id,
        cardId: cardType.card_id,
        journalEntry: content,
        backgroundColor: color,
      });
      navigate("/app/journal");
    } catch (error) {}
  };

  const updateEntry = async () => {
    try {
      const response = await editEntry({
        journalId: selectedEntry.journal_id,
        userId: user.user_id,
        journalEntry: content,
        backgroundColor: color,
      });
      navigate("/app/journal");
    } catch (error) {}
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px 20px 100px",
        color: "black",
        position: "relative",
      }}
      className="journalContainer"
    >
      {!newEntry && (
        <Box
          className="journal-circle"
          sx={{
            left: "40px",
            border: `1px solid #623535`,
          }}
          onClick={handleDeleteEntryClick}
        >
          <DeleteIcon sx={{ fontSize: "30px", color: "#623535" }} />
        </Box>
      )}
      <Box
        className="journal-circle"
        sx={{
          right: "120px",
          backgroundColor: uiConstants.DARK_PURPLE,
        }}
        onClick={handleCancel}
      >
        <ClearIcon sx={{ fontSize: "30px", color: "white" }} />
      </Box>
      <Box
        className="journal-circle"
        sx={{
          right: "40px",
          backgroundColor: uiConstants.PURPLE,
        }}
        onClick={newEntry ? createNewEntry : updateEntry}
      >
        <SaveIcon sx={{ fontSize: "30px", color: "white" }} />
      </Box>

      <Box
        sx={{
          paddingY: "16px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          {newEntry ? (
            <CreateEntryHeader handleOpen={handleOpen} cardType={cardType} />
          ) : (
            <EntryHeader cardType={cardType} />
          )}
          <JournalCardSelector
            cardOptions={cardOptions}
            setCardType={setCardType}
          />
          <AlertDialog
            open={isDialogOpen}
            setOpen={setDialogOpen}
            dialogText={dialogText}
            selectedEntry={selectedEntry}
          />
        </Box>
        <Box style={{ justifyContent: "flex-end" }} className="journal-mobile-circle">
          {!newEntry && (
            <Box
              sx={{
                marginRight: "10px",
                border: `1px solid #623535`,
              }}
              className="journal-mobile-circle-item"
              onClick={handleDeleteEntryClick}
            >
              <DeleteIcon sx={{ fontSize: "20px", color: "#623535" }} />
            </Box>
          )}
          <Box
            sx={{
              marginRight: "10px",
              backgroundColor: uiConstants.DARK_PURPLE,
            }}
            className="journal-mobile-circle-item"
            onClick={handleCancel}
          >
            <ClearIcon sx={{ fontSize: "20px", color: "white" }} />
          </Box>
          <Box
            sx={{
              backgroundColor: uiConstants.PURPLE,
            }}
            className="journal-mobile-circle-item"
            onClick={newEntry ? createNewEntry : updateEntry}
          >
            <SaveIcon sx={{ fontSize: "20px", color: "white" }} />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            margin: "6px 0 16px",
            paddingInline: "16px",
            justifyContent: "space-between",
          }}
        >
          {colors.map((color) => (
            <Box
              key={color}
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: color,
                cursor: "pointer",
              }}
              onClick={() => setColor(color)}
            ></Box>
          ))}
        </Box>
        <Box
          sx={{
            bgcolor: color,
            borderRadius: "12px 12px 0 0",
          }}
        >
          <EntryTextEditor
            content={content}
            handleContentChange={handleContentChange}
          />
        </Box>
        
      </Box>
    </Box>
  );
};

export default EditEntryPage;

import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    userEntries: [],
    cardSelectorOpen: false
};
const getJournalFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("journal")) || defaultState;
};

const journalSlice = createSlice({
  name: "journalState",
  initialState: getJournalFromLocalStorage,
  reducers: {

    setUserEntries: (state, action) => {
      state.userEntries = action.payload
      localStorage.setItem("journal", JSON.stringify(state));
    },
    closeCardSelector: (state) => {
      state.cardSelectorOpen = false;
      localStorage.setItem("journal", JSON.stringify(state));
    },
    openCardSelector: (state) => {
      state.cardSelectorOpen = true;
      localStorage.setItem("journal", JSON.stringify(state));
    },

  }
});

export const {
  setUserEntries,
  closeCardSelector,
  openCardSelector

} = journalSlice.actions;

export default journalSlice.reducer;

export const TermsText = () => {
    return (
      <div className="terms-and-policy-container terms-background">
         <p>
        Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the Virtues Cards mobile application (the "Service") operated by The Heart of Education LLC dba (doing business) as Virtues Matter ("us", "we", "our" or “Company”).
      </p>
      <p>
        Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service.
      </p>
      <p>
        By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.
      </p>
      <h3>Privacy Policy</h3>
      <p>
        Before you continue using our app, we advise you to read our privacy policy regarding our user data collection. It will help you better understand our practices.
      </p>
      <h3>Copyright</h3>
      <p>
        Content published in our mobile app (digital downloads, images, texts, graphics, logos) are the property of The Heart of Education LLC and/or its content creators and protected by international copyright laws. Modification of the Content or use of the Content for any other purpose is a violation of the copyright and other proprietary rights of the Company, as well as other authors who created the materials, and may be subject to monetary damages and penalties. You may not distribute, modify, transmit or use the content including any and all software, tools, graphics and/or sound files, for public or commercial purposes without the express written permission of The Heart of Education LLC.
      </p>
      <h3>Trademarks</h3>
      <p>
        All trade names, trademarks, images, and biographical information of people used in the Service, including without limitation the name “Virtues Cards” and “Virtues Matter”, are either the property of, or used with permission by, the Company. The use of Content by you is strictly prohibited unless specifically permitted by these Terms of Use.
      </p>
      <h3>Communications</h3>
      <p>
        When you register with the Company and/or this Site, you expressly consent to receive any notices, announcements, agreements, disclosures, reports, documents, communications concerning new products or services, or other records or correspondence from the Company. You consent to receive notices electronically by way of transmitting the notice to you by email. We will also continue to communicate with you by updating and sending you notifications through the app. If you send comments or suggestions about the app to the Company, including, but not limited to, notes, text, drawings, images, designs or computer programs, such submissions shall become, and shall remain, the sole property of the Company. No submission shall be subject to any obligation of confidence on the part of the Company. The Company shall exclusively own all rights to (including intellectual property rights thereto), and shall be entitled to unrestricted use, publication, and dissemination as to all such submissions for any purpose, commercial or otherwise without any acknowledgment or compensation to you.
      </p>
      <h3>Purchases</h3>
      <p>
        If you wish to purchase any product or service made available through the Service ("Purchase"), you may be asked to supply certain information relevant to your Purchase.
      </p>
      <h3>Applicable Law</h3>
      <p>
        By using this app, you agree that the laws of Maryland, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between The Heart of Education LLC and you, or its business partners and associates.
      </p>
      <h3>Disputes</h3>
      <p>
        Any dispute related in any way to your visit to this website or to products you purchase from us shall be arbitrated by state or federal court in Maryland and you consent to exclusive jurisdiction and venue of such courts.
      </p>
      <h3>Indemnity</h3>
      <p>
        You agree to indemnify and hold the Company and each of its directors, officers employees, and agents, harmless from any and all liabilities, claims, damages and expenses, including reasonable attorney’s fees, arising out of or relating to (i) your breach of this Agreement, (ii) any violation by you of law or the rights of any third party, (iii) any materials, information, works and/or other content of whatever nature or media that you post or share on or through the app, (iv) your use of the Site or any services that the Company may provide via the Site, and (v) your conduct in connection with the app or the services or with other users of the app or the services.
      </p>
      <h3>Changes</h3>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
      </p>
      <p>
        User Account: If you are an owner of an account on this app, you are solely responsible for maintaining the confidentiality of your private user details (username and password). You are responsible for all activities that occur under your account or password. We reserve all rights to terminate accounts, edit or remove content and cancel orders in their sole discretion.
      </p>
      <h3>Updates</h3>
      <p>
        These Terms and Conditions may be revised from time to time by updating this posting. You are bound by any such revisions and should therefore periodically visit this page to review the then current Terms and Conditions to which you are bound.
      </p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions about these Terms, please contact us:
        <br />
        The Heart of Education LLC
        <br />
        service@virtuesmatter.org
        <br />
        301-949-0414
      </p>
      </div>
    );
  };



import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faHeart,
  faSuitClub,
  faShuffle
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import {
  BottomNavigation,
  BottomNavigationAction,
  Alert,
  Snackbar,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const BottomBar = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState();
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname.split("/")[2];
    const pathObjects = {
      home: "home",
      shop: "shop",
      "shop-checkout": "shop",
      journal: "journal",
    };
    setValue(pathObjects[pathname]);
  }, [location]);
  const handleChange = (event, newValue) => {
    navigate(`/app/${newValue}`);
  };

  return (
    <>
      <BottomNavigation
        value={value}
        onChange={handleChange}
        showLabels
        style={{
          width: "100%",
          maxWidth: "400px",
          position: "absolute",
          height: "55px",
          right: "50%",
          transform: "translateX(50%)",
          bottom: "0px",
          zIndex: "1000",
        }}
      >
        <BottomNavigationAction
          label="Home"
          value="home"
          icon={<FontAwesomeIcon icon="home"  style={{fontSize: '15px'}} />}
          sx={{
            "&.Mui-selected": {
              color: "#d81b60",
            },
          }}
        />
        <BottomNavigationAction
          label="Shop"
          value="shop"
          icon={<FontAwesomeIcon icon="photo-film" style={{fontSize: '15px'}} />}
          size="20"
          sx={{
            "&.Mui-selected": {
              color: "#d81b60",
            },
          }}
        />
        <BottomNavigationAction
          label="Journal"
          value="journal"
          icon={<FontAwesomeIcon icon="file-text"  style={{fontSize: '15px'}}/>}
          sx={{
            "&.Mui-selected": {
              color: "#d81b60",
            },
          }}
        />
        <BottomNavigationAction
          label="Random Pick"
          value="home?shuffleCard=true"
          icon={<FontAwesomeIcon icon={faShuffle} style={{fontSize: '15px'}} />}
          sx={{
            "&.Mui-selected": {
              color: "#d81b60",
            },
          }}
        />
      </BottomNavigation>
    </>
  );
};

export default BottomBar;

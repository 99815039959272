import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CheckIcon from "@mui/icons-material/Check";
import ShareIcon from "@mui/icons-material/Share";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import { Constants, queryClient } from "../../utils";
import { syncUserCardsData } from "../../logic/Requests";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateCardStatus } from "../../features/cards/cardsSlice";
import { closeCard, setCardFrontSide } from "../../features/ui/uiSlice";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";

const CardDetailTopBar = ({ card }) => {
  const { is_fave, is_read, user_card_id, imageUrl } = card; // Ensure card has imageUrl
  const [isFave, setIsFave] = useState(is_fave);
  const [isRead, setIsRead] = useState(is_read);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setIsFave(is_fave);
    setIsRead(is_read);
  }, [is_fave, is_read, card]);
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.userState.user.user_id);
  const language = "EN";

  const shareUrl = [
    "https://virtuecards.s3.amazonaws.com/processed/packages" +
      card.img_card +
      process.env.REACT_APP_IMAGES_VERSION,
    "https://virtuecards.s3.amazonaws.com/processed/packages" +
      card.img_card_back +
      process.env.REACT_APP_IMAGES_VERSION,
  ]; // URL of the card
  const shareMessage = `A Gift For You. Discover more Virtues at www.virtuescards.org\n${shareUrl.join(
    "\n"
  )}`;

  const title = "A Gift For You. Discover more Virtues at www.virtuescards.org";
  const whatsappMessage = `${title}\n${shareUrl.join("\n")}`;
  const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    whatsappMessage
  )}`;

  const resizeImage = (imageBlob, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(imageBlob);
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Calculate new dimensions
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = Math.round((width * maxHeight) / height);
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        // Draw the image
        ctx.drawImage(img, 0, 0, width, height);

        // Convert canvas to blob
        canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg",
          0.9
        );
      };
      img.onerror = (error) => reject(error);
    });
  };

  const handleWebShare = async () => {
    if (navigator.share) {
      try {
        const imgCardResponse = await fetch(
          "https://virtuecards.s3.amazonaws.com/processed/packages" +
            card.img_card +
            process.env.REACT_APP_IMAGES_VERSION
        );
        const imageBlobCard = await imgCardResponse.blob();

        const imgCardBackResponse = await fetch(
          "https://virtuecards.s3.amazonaws.com/processed/packages" +
            card.img_card_back +
            process.env.REACT_APP_IMAGES_VERSION
        );
        const imageBlobCardBack = await imgCardBackResponse.blob();

        // Resize the images
        const resizedImageBlobCard = await resizeImage(imageBlobCard, 500, 735);
        const resizedImageBlobCardBack = await resizeImage(
          imageBlobCardBack,
          500,
          735
        );

        // Create File objects from resized blobs
        const imageCardFile = new File(
          [resizedImageBlobCard],
          "virtues_card.jpg",
          {
            type: "image/jpeg",
          }
        );
        const imageCardBackFile = new File(
          [resizedImageBlobCardBack],
          "virtues_card_back.jpg",
          { type: "image/jpeg" }
        );

        // Use Web Share API
        await navigator.share({
          title:
            "A Gift For You. Discover more Virtues at www.virtuescards.org",
          files: [imageCardFile, imageCardBackFile],
        });

        console.log("Share was successful.");
      } catch (error) {
        console.error("Share failed:", error);
      }
    } else {
      console.log("Web Share API is not supported in this browser.");
      setAnchorEl(document.getElementById("menu-share"));
    }
  };

  const handleBack = () => {
    dispatch(closeCard());
    dispatch(setCardFrontSide());
  };

  const mutation = useMutation({
    mutationFn: syncUserCardsData,
    onSuccess: async () => {
      queryClient.refetchQueries({ queryKey: ["userCards", userId, language] });
    },
  });

  const handleClick = async (updateMode) => {
    const newFavValue =
      updateMode === Constants.UPD_MODE_USERCARD_FAVE ? !isFave : isFave;
    const newReadValue =
      updateMode === Constants.UPD_MODE_USERCARD_READ ? !isRead : isRead;

    await mutation.mutate([
      {
        user_card_id,
        is_fave: newFavValue ? 1 : 0,
        is_read: newReadValue ? 1 : 0,
      },
    ]);

    setIsFave(newFavValue);
    setIsRead(newReadValue);

    dispatch(
      updateCardStatus({
        user_card_id,
        is_fave: newFavValue ? 1 : 0,
        is_read: newReadValue ? 1 : 0,
      })
    );
  };

  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{ bgcolor: "transparent", boxShadow: "none" }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
            sx={{ cursor: "pointer" }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Box>
            <IconButton
              color="inherit"
              aria-label="favorite"
              sx={{ color: +isFave === 1 ? "red" : "white" }}
              onClick={() => handleClick(Constants.UPD_MODE_USERCARD_FAVE)}
            >
              <FavoriteIcon />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="check"
              sx={{ color: +isRead === 1 ? "green" : "white" }}
              onClick={() => handleClick(Constants.UPD_MODE_USERCARD_READ)}
            >
              <CheckIcon />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="share"
              onClick={handleWebShare}
              id="menu-share"
            >
              <ShareIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem>
          <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
            <WhatsappIcon size={32} round />
          </a>
        </MenuItem>
        <MenuItem>
          <FacebookShareButton url={shareUrl[0]} quote={shareMessage}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        </MenuItem>
        <MenuItem>
          <TwitterShareButton url={shareUrl[0]} title={shareMessage}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </MenuItem>
        <MenuItem>
          <EmailShareButton
            url={shareUrl[0]}
            subject="A Gift For You. Discover more Virtues at www.virtuescards.org"
            body={shareMessage}
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </MenuItem>
      </Menu>
    </>
  );
};

export default CardDetailTopBar;

import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { AXIOS_CONFIG, UPDATE_MODE, API_URL } from "../utils/constants";



export const registerUser = async (userData) => {
  try {
    let data = {
      firstName: userData.firstName.trim(),
      lastName: userData.lastName.trim(),
      userName: "",
      gender: "MAL",
      email: userData.email.trim(),
      phoneNo: 0,
      password: userData.password,
      whoAdded: 1,
    };
    const response = await axios.post(`${API_URL}/public/user/register`, data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const registerSocialUser = async (idToken, social) => {
  const decoded = jwtDecode(idToken);
  const requestBody = {
    socialLoginType: social,
    serverAuthCode: null,
    idToken,
    photo: decoded.picture,
    email: decoded.email,
    firstName: decoded.given_name,
    lastName: decoded.family_name,
    socialId: decoded.sub,
    name: decoded.name,
  };

  const requestBodySignIn = {
    idToken,
    firstName: decoded.given_name,
    lastName: decoded.family_name,
    email: decoded.email,
    googleId: decoded.sub,
  };

  try {
    const response = await axios.post(
      `${API_URL}/socialLogin/user/register`,
      requestBody
    );
    return response.data;
  } catch (error) {
    console.error("Error registering social user:", error);
  }
  try {
    // Make the request to your backend endpoint
    const response = await axios.post(
      `${API_URL}/auth/signin/social/google`,
      requestBodySignIn
    );
    return response.data; // Return the response data
  } catch (error) {
    console.error("Error signing in with Google:", error);
    throw error; // Propagate the error for handling by the caller
  }
};

export const signIn = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/auth/signin`, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const signInGoogle = async (idToken) => {
  const decoded = jwtDecode(idToken);
  const requestBody = {
    idToken,
    firstName: decoded.given_name,
    lastName: decoded.family_name,
    email: decoded.email,
    googleId: decoded.sub,
  };

  try {
    // Make the request to your backend endpoint
    const response = await axios.post(
      `${API_URL}/auth/signin/social/google`,
      requestBody
    );
    return response.data; // Return the response data
  } catch (error) {
    console.error(error);
    throw error.response.data;
  }
};


export const getUserDecks = async (userId, langCode) => {
  try {
    const response = await axios.get(`${API_URL}/userdeck/user/${userId}`, {
      params: { lang_code: langCode },
    });
    return response.data;
  } catch (error) {
    console.error("Error signing in with Google:", error);
    throw error;
  }
};

export const getAllUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}/users`);
    return response.data;
  } catch (error) {}
};

export const getUserByGoogleId = async (idToken) => {
  const decoded = jwtDecode(idToken);

  try {
    const response = await axios.get(
      `${API_URL}/socialLogin/user/google/${decoded.sub}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
export const syncUserCardsData = async (userCards) => {
  try {
    const apiPath = `${API_URL}/usercard/sync`;
    const body = { user_cards: userCards };
    const headers = {
      'X-Requested-With': AXIOS_CONFIG.XML_HTTP_REQUEST,
      'Authorization': null,
      // 'Csrf-token': AXIOS_CONFIG.CSRF_TOKEN,
      "Content-Type": "application/json",
    };

    let response = await axios.post(apiPath, body, { headers });

    if (response.data.status.code === "0") {
      return true;
    } else {
      console.error("response.data.status: ", response.data.status);
      return false;
    }
  } catch (error) {
    console.error("error: ", error);
    throw error.response.data;
  }
};

export const forgotPassword = async (email) => {
  const requestBody = { email };

  try {
    const response = await axios.post(
      `${API_URL}/user/forgotpassword`,
      requestBody
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const addSampleCardsToUser = async (userId) => {
  const requestBody = { userId };
  try {
    const response = await axios.post(
      `${API_URL}/usercard/add/sam`,
      requestBody
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateUserProfile = async ({
  firstName,
  lastName,
  email,
  userId,
  token,
}) => {
  const headers = {
    "X-Requested-With": AXIOS_CONFIG.XML_HTTP_REQUEST,
    // 'Authorization': `Bearer ${token}`,
    "Csrf-token": AXIOS_CONFIG.CSRF_TOKEN,
    XML_HTTP_REQUEST: "XMLHttpRequest",
    // 'Content-Type': 'application/json'
  };

  const requestBody = {
    firstName,
    lastName,
    email,
    profileDescription: " ",
    whoUpdated: userId,
  };

  try {
    const response = await axios.put(
      `${API_URL}/user/${userId}/profile/${UPDATE_MODE.USER_DETAILS}`,
      requestBody,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error.response.data;
  }
};

export const updatePassword = async ({ userId, updValue }) => {
  const requestBody = {
    updValue,
    whoUpdated: userId,
  };

  try {
    const response = await axios.put(
      `${API_URL}/user/${userId}/${UPDATE_MODE.PASSWORD}`,
      requestBody
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

import React from "react";
import { Box, Typography } from "@mui/material";
import { uiConstants } from "../../utils/constants";

function JournalEmpty() {
  return (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Typography
        variant="h6"
        sx={{ color: uiConstants.BLUE, fontWeight: "light" }}
      >
        {" "}
        Tap the pencil icon to create a journal entry.
      </Typography>
    </Box>
  );
}

export default JournalEmpty;

import React, { forwardRef } from "react";
import { Box, Typography } from "@mui/material";

const DeckSelectorItem = forwardRef(
  ({ deckType, selectedDeck, handleDeckTypeClick }, ref) => {
    return (
      <Box
        ref={ref}
        className="boxShadowCard"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginRight: "20px",
          cursor: "pointer",
          color: deckType.title === selectedDeck ? "white" : deckType.color,
          backgroundColor:
            deckType.title === selectedDeck ? deckType.color : "transparent",
          border: `2px solid ${deckType.color}`,
          padding: "5px 30px",
          borderRadius: "15px",
          width: "130px",
          minWidth: "140px",
          height: "90%",
          boxSizing: "border-box",
          transition: "all 0.3s ease",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          "&:hover": { transform: "scale(0.9)" },
        }}
        onClick={() => handleDeckTypeClick(deckType)}
      >
        <Box
          sx={{
            width: "50px",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "2.5rem",
            padding: "0px",
          }}
        >
          <deckType.icon style={{ fontSize: "1.8rem" }} />
        </Box>
        <Typography>{deckType.name}</Typography>
      </Box>
    );
  }
);

export default DeckSelectorItem;

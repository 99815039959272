import React from "react";
import { Outlet, useNavigation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { BottomBar, SnackbarComponent, TopAppBarWithMenu } from "../components";
import { Box, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar } from "../features/ui/uiSlice";

const ProtectedRoute = () => {
  const isAuthenticated = localStorage.getItem("user");
  const navigation = useNavigation();
  const isPageLoading = navigation.state === "loading";
  const dispatch = useDispatch();

  const { openSnackbar, message, type } = useSelector(
    (state) => state.uiState.snackBarProps
  ) ?? {
    openSnackbar: false,
    message: "",
    type: "",
  };

  const handleCloseSnackbar = () => {
    dispatch(closeSnackbar());
  };


  return isAuthenticated ? (
    <main style={{ height: "100%", width: '100%', overflow: "hidden", position: "fixed", top: 0 }}>
      <TopAppBarWithMenu />
      <SnackbarComponent
        openSnackbar={openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        message={message}
        type={type}
      />
      <Box sx={{ height: "100%", maxWidth: "400px", width: "100%", margin: "auto" }}>
        {isPageLoading ? <CircularProgress /> : <Outlet />}
      </Box>
      <BottomBar />
    </main>
  ) : (
    <Navigate to="/signin" />
  );
};

export default ProtectedRoute;

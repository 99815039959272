import { configureStore, combineReducers } from '@reduxjs/toolkit';
import userReducer from './features/user/userSlice';
import uiReducer from './features/ui/uiSlice';
import cardsReducer from './features/cards/cardsSlice';
import journalReducer from './features/journal/journalSlice'

const rootReducer = combineReducers({
  userState: userReducer,
  uiState: uiReducer,
  cardsState: cardsReducer,
  journalState: journalReducer
});

export const store = configureStore({
  reducer: rootReducer,
});
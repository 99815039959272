import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Modal,
  Button,
  List,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FilterCardItem from "./FilterCardItem";
import DoneIcon from "@mui/icons-material/Done";
import { useDispatch } from "react-redux";
import {
  toggleSearchBar,
  compactCards,
  listCards,
} from "../../features/ui/uiSlice";
import { setFilterToCards } from "../../features/cards/cardsSlice";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AppsIcon from "@mui/icons-material/Apps";
import { useSelector } from "react-redux";

const FilterComponent = () => {
  const { isListCards } = useSelector((state) => state.uiState);
  const { isCompactCards } = useSelector((state) => state.uiState);
  const [openFilter, setOpenFilter] = useState(false);

  const dispatch = useDispatch();

  const toggleSearch = () => {
    dispatch(toggleSearchBar());
  };

  const handleList = () => {
    dispatch(listCards());
  };

  const handleCompact = () => {
    dispatch(compactCards());
  };

  const handleOpenFilter = () => setOpenFilter(true);
  const handleCloseFilter = () => setOpenFilter(false);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        backgroundColor: "#fcfcfc",
        width: "100%", // Adjust width according to your requirement
        maxWidth: "400px", // Adjust width according to your requirement
        height: "35px",
        color: "#B9B9B9",
        borderColor: "#c3c3c3",
        padding: "0 10px",
        zIndex: 5,
        alignSelf: "stretch",
      }}
    >
      <IconButton aria-label="list" onClick={handleList}>
        <FormatListBulletedIcon sx={{ color: isListCards ? "green" : "" }} />
      </IconButton>
      <IconButton aria-label="compact" onClick={handleCompact}>
        <AppsIcon sx={{ color: isCompactCards ? "green" : "" }} />
      </IconButton>

      <IconButton aria-label="filter" onClick={handleOpenFilter}>
        <Typography variant="h6" sx={{ fontSize: "0.8rem" }}>
          Filter
        </Typography>
        <FilterAltIcon />
      </IconButton>

      <IconButton aria-label="search" onClick={toggleSearch}>
        <SearchIcon />
      </IconButton>
      <Modal
        open={openFilter}
        onClose={handleCloseFilter}
        aria-labelledby="choose-deck-modal"
        aria-describedby="choose-deck-modal-description"
      >
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: "50%",
            translate: "-50%",
            width: "100%",
            maxWidth: "400px",
            height: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            padding: "0 10px 10px 10px",
            textAlign: "center",
            borderRadius: "10px 10px 0 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              zIndex: 2,
              height: "60px",
              borderBottom: "2px solid whiteSmoke",
            }}
          >
            <Button
              onClick={() => {
                dispatch(setFilterToCards("not filtered"));
              }}
            >
              Show all cards
            </Button>
            <Typography variant="h6" component="h2">
              Filter
            </Typography>
            <Button onClick={handleCloseFilter}>Apply</Button>
          </Box>
          <List sx={{ marginTop: "10px", overflow: "auto" }}>
            <FilterCardItem
              icon={<FavoriteIcon />}
              filter={"is_fave"}
              text="Favorite"
              checked={true}
              iconColor="#c61293"
            />
            <FilterCardItem
              icon={<DoneIcon />}
              filter={"is_read"}
              text="Checked"
              checked={false}
              iconColor="green"
            />
          </List>
        </Box>
      </Modal>
    </Box>
  );
};

export default FilterComponent;

import { createSlice } from "@reduxjs/toolkit";

const getUserFromLocalStorage = () => {
  const user = JSON.parse(localStorage.getItem("user")) || null;
  const hasVisited = JSON.parse(localStorage.getItem("hasVisited")) || null;
  const isSignedIn = JSON.parse(localStorage.getItem("isSignedIn")) || null;
  return { user, hasVisited, isSignedIn };
};


const initialState = { ...getUserFromLocalStorage() };

const userSlice = createSlice({
  name: "userState",
  initialState: initialState,
  reducers: {
    loginUser: (state, action) => {
      const user = {
        user_id: action.payload.user_id,
        token: action.payload.token,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        email: action.payload.email,
      };

      state.user = user;
      state.isSignedIn = true;
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("isSignedIn", state.isSignedIn);
      if (localStorage.getItem("firstLogin")) {
        localStorage.setItem("firstLogin", "false");
      }else {
        localStorage.setItem("firstLogin", "true");
      }
    },

    logOutUser: (state) => {
      state.user = null;
      state.isSignedIn = false;
      localStorage.removeItem("user");
      localStorage.removeItem("isSignedIn");
      localStorage.removeItem("decks");
      localStorage.removeItem("ui");
      localStorage.removeItem("journal");
    },

    userVisited: (state) => {
      state.hasVisited = true;
      localStorage.setItem("hasVisited", "true");
    },
  },
});

export const { loginUser, logOutUser, userVisited } = userSlice.actions;

export default userSlice.reducer;

import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import SpaIcon from "@mui/icons-material/Spa";
import { MdFamilyRestroom } from "react-icons/md";
import { FaPeopleGroup } from "react-icons/fa6";
import { SiBookstack } from "react-icons/si";
import { FaHeart } from "react-icons/fa";
import cardImage from "../assets/images/placeholders/placeHolderCard.png";
import cardImage2 from "../assets/images/placeholders/placeHolderCard2.png";
import cardImage3 from "../assets/images/placeholders/placeHolderCard2.jpeg";
import cardImage4 from "../assets/images/placeholders/placeHolderCard4.png";
import cardImage5 from "../assets/images/placeholders/placeHolderCard5.png";

// ============> CONSTANTS FROM WEB APP <============

export const uiConstants = {
  PURPLE: "#B01F8D",
  DARK_PURPLE: "#551883",
  BLUE: "#2b3a80",
  GREY: "#bdbdccd6",
  OPACITY_BLACK: "#09080875",
};

export const API_URL = process.env.REACT_APP_API_URL;

export const S3_URL = process.env.REACT_APP_S3_URL;

export const navBarObjects = [
  { title: "Explore Decks", pathName: "/app/home" },
  // TOP NAVBAR
  { title: "Tutorial", pathName: "/app/learn-more/tutorial" },
  { title: "Resources", pathName: "/app/learn-more/resources" },
  { title: "About Us", pathName: "/app/learn-more/about-us" },
  { title: "Redeem Code", pathName: "/app/learn-more/redeem-codes" },
  { title: "Account", pathName: "/app/account" },
  // CHECKOUT
  { title: "Reflection Cards", pathName: "/app/shop-checkout/REF" },
  { title: "Resilience Cards", pathName: "/app/shop-checkout/SUN" },
  { title: "Education Cards", pathName: "/app/shop-checkout/EDU" },
  { title: "Family Cards", pathName: "/app/shop-checkout/FAM" },
  { title: "Character Cards", pathName: "/app/shop-checkout/CHA" },
  { title: "DECA Cards", pathName: "/app/shop-checkout/DEC" },
  // BOTTOM NAVBAR
  { title: "Shop", pathName: "/app/shop" },
  { title: "Journal", pathName: "/app/journal" }
];

export const deckTypes = [
  {
    name: "Reflection",
    title: "Virtues Reflection Cards",
    icon: SelfImprovementIcon,
    color: "rgba(176, 31, 141, 1)",
    sampleCards: 7,
    backgroundColor: "rgba(65,5,66,0.8583307072829132)",
    img1: cardImage,
    img2: cardImage4,
    img3: cardImage,
    code: "REF",
  },
  {
    name: "Resilience",
    title: "Virtues Resilience Cards",
    icon: SpaIcon,
    color: "rgba(8, 60, 98, 1)",
    sampleCards: 5,
    backgroundColor: "rgba(10,19,38,0.9024203431372549)",
    img1: cardImage2,
    img2: cardImage3,
    img3: cardImage5,
    code: "SUN",
  },
  {
    name: "Education",
    title: "Virtues Education  Cards",
    icon: SiBookstack,
    color: "rgba(99, 58, 116, 1)",
    sampleCards: 5,
    backgroundColor: "rgba(28,10,38,0.9024203431372549)",
    img1: cardImage3,
    img2: cardImage,
    img3: cardImage4,
    code: "EDU",
  },
  {
    name: "Family",
    title: "Family Virtues Cards",
    icon: MdFamilyRestroom,
    color: "rgba(176, 31, 141, 1)",
    sampleCards: 5,
    backgroundColor: "rgba(65,5,66,0.8583307072829132)",
    img1: cardImage4,
    img2: cardImage2,
    img3: cardImage,
    code: "FAM",
  },
  {
    name: "Character",
    title: "Virtues Character Cards",
    icon: FaPeopleGroup,
    color: "rgba(8, 60, 98, 1)",
    sampleCards: 5,
    backgroundColor: "rgba(10,19,38,0.9024203431372549)",
    img1: cardImage5,
    img2: cardImage,
    img3: cardImage2,
    code: "CHA",
  },
  {
    name: "DECA",
    title: "Virtues DECA Cards",
    icon: FaHeart,
    color: "rgba(99, 58, 116, 1)",
    sampleCards: 5,
    backgroundColor: "rgba(28,10,38,0.9024203431372549)",
    img1: cardImage,
    img2: cardImage5,
    img3: cardImage,
    code: "DEC",
  },
];

export const CARD_TYPES = ["REF", "SUN", "FAM", "EDU", "CHA", "DEC"];

export const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY

export const DECK_SHOP_DESCRIPTION = {
    REF: ['Ideal for individual reflection or sharing circles.', 'This signature deck contains 100 full-color images of natural beauty from around the globe.', 'Six ways to practice each virtue based on research from diverse traditions and ancient wisdom.', 'These cards can be used personally, in relationships, or with teams.'],
    SUN: ['Simple and elegant.', 'These 56 cards contain colorful sunset photos, and brief, inspiring words to bring a moment of peace and mindfulness to your day.', 'The messages provide a quick insight, motivation, or uplifting shift of energy at any time.'],
    FAM: ['These 52 cards teach the language of self-esteem and integrity while nurturing family unity.', 'Each card offers inspirational multi-faith quotes, descriptions of each virtue, and signs of success.', 'These cards are perfect for character building for the whole family.'],
    EDU: ['The 52 Virtues Education Cards are a kid-friendly tool used by schools across the globe to strengthen school culture and climate, and to enhance Social Emotional Learning (SEL), character education resilience, mindfulness and restorative practice programs.',' Useful in the classroom, counseling sessions, and sharing circles.'],
    CHA: ['Character is the way we think, feel, and behave.','This set of 120 contemplative cards features moral and performance qualities for use in daily reflection, guidance and practice.',' Good for leadership, personal, and organizational development.',' These cards have no reference to religion.'],
    DEC: ['The  DECA Cards were designed as a partnership between Virtues Matters and Washington DECA.', 'DECA students created all images, selected quotes and modified the text from The Virtues Project Reflection Cards.'],
}

export const DECK_PRICE = {
    REF: "4.99",
    SUN: "4.99",
    FAM: "0.99",
    EDU: "0.99",
    CHA: "4.99",
    DEC: "4.99",
}

export const DECK_PRICE_ID ={
    REF: process.env.REACT_APP_REFLECTION_CARDS_PRICE,
    SUN: process.env.REACT_APP_RESILIENCE_CARDS_PRICE,
    FAM: process.env.REACT_APP_FAMILY_CARDS_PRICE,
    EDU: process.env.REACT_APP_EDUCATION_CARDS_PRICE,
    CHA: process.env.REACT_APP_CHARACTER_CARDS_PRICE,
    DEC: process.env.REACT_APP_DECA_CARDS_PRICE,
}

export const JOURNAL_DIALOG_TEXT = {
    discard: "Are you sure you want to discard your changes?",
    delete: "Are you sure you want to delete this entry?",
};

// ============> CONSTANTS FROM MOBIILE APP <============
export const Constants = {
  LOCATION_TOP: "TOP",
  LOCATION_BOT: "BOT",
  VIEW_BACK: "BACK",
  VIEW_FRONT: "FRONT",

  // AXIOS CONFIG HEADERS
  XML_HTTP_REQUEST: "XMLHttpRequest",
  CSRF_TOKEN: "nocheck",

  UPD_MODE_USERCARD_FAVE: "is_fave",
  UPD_MODE_USERCARD_READ: "is_read",
};

export const CHAT = {
  MAX_MESSAGE_COUNT: 200,
  TYPE_PRIVATE: "chat-pri",
  TYPE_PUBLIC: "chat-pub",
  TYPE_GROUP: "chat-grp",
};

export const CARD_LOCATION = {
  TOP: "top",
  BOT: "bot",
};

export const CARD_VIEW = {
  BACK: "BACK",
  FRONT: "FRONT",
};

export const USER_GENDER_CONST = {
  MAL: "MAL",
  FEM: "FEM",
};

export const DEFAULT_PROFILE_IMG = {
  DEF: "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTS1EHt1QgdGFdJk56iHMdTiWyC86nqwakkGeHqDte4fOLuGbu4",
  MAL: "https://sophosnews.files.wordpress.com/2013/08/facebook-silhouette_thumb.jpg?w=250",
  FEM: "https://images.squarespace-cdn.com/content/v1/5970cd61e6f2e1822b6940c7/1552928946061-80NFM21QNYNZVY1QPS0I/ke17ZwdGBToddI8pDm48kOKLA4nWpu_7gGxQDQqnHdp7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QHyNOqBUUEtDDsRWrJLTmyh-8_5GJNvrfz4o4yOfLSyHGVVZJeCSdaZrJmaJfB8tVsgs5eGZSZFqbsy-olSCh/facebook-default-no-profile-pic-girl.jpeg?format=250w",
};

export const USER_CARD = {
  FAVE: "is_fave",
  READ: "is_read",
};

export const USER_PROFILE_UPD_MODE = {
  MEDIA_PROFILE: "media_profile",
};

export const USER_CREDS_UPD_MODE = {
  EMAIL: "email",
  PASSWORD: "password",
};

export const STATUS = {
  SUCCESS: "0",
  FAILED: "-100",
};

export const SYNC_TYPE = {
  ADD: "ADD",
  UPD: "UPD",
  DEL: "DEL",
};

export const AXIOS_CONFIG = {
  XML_HTTP_REQUEST: "XMLHttpRequest",
  CSRF_TOKEN: "nocheck",
};

export const GROUP_TYPE = {
  PRI: "PRI",
  GRO: "GRO",
  PUB: "PUB",
};

export const CARD_TYPE = {
  REF: "REF",
  SUN: "SUN",
  FAM: "FAM",
  EDU: "EDU",
  CHA: "CHA",
  DEC: "DEC",
};

export const CARD_DECK = {
  REF: "REF",
  SUN: "SUN",
  FAM: "FAM",
  EDU: "EDU",
  SAM: "SAM",
  CHA: "CHA",
  DEC: "DEC",
};
export const DECK_BG_COLOR = {
  REF: "#00285C",
  SUN: "#0C4786",
  FAM: "#FFFFFF",
  EDU: "#023473",
  CHA: "#FFFFFF",
  DEC: "#00539b",
};

export const CALENDAR_DAYS_CODE = {
  SUNDAY: "Su",
  MONDAY: "M",
  TUESDAY: "T",
  WEDNESDAY: "W",
  THURSDAY: "Th",
  FRIDAY: "F",
  SATURDAY: "S",
};

export const CARD_DECK_DESCRIPTION = {
  REF: `Virtues Reflection ${"\n\t"} Cards`,
  SUN: `Virtues Resilience Cards ${"\n"}`,
  FAM: "Virtues Family Cards",
  EDU: "Virtues Education Cards",
  CHA: "Virtues Character Cards",
  DEC: "Virtues DECA Cards",
};

export const DECK_PURCHASE_CARD_TITLE = {
  REF: `Virtues Reflection Cards`,
  SUN: "Virtues Resilience Cards",
  FAM: `Family Virtues Cards`,
  EDU: `Virtues Education  Cards`,
  CHA: "Virtues Character Cards",
  DEC: "Virtues DECA Cards",
};

export const DECK_PURCHASE_DECK_DESCRIPTION = {
  REF: `Ideal for individual reflection or sharing circles, this signature deck contains 100 full-color images of natural beauty from around the globe. With six ways to practice each virtue based on research from diverse traditions and ancient wisdom, these cards can be used personally, in relationships, or with teams.`,
  SUN: `Simple and elegant, these 56 cards contain colorful sunset photos, and brief, inspiring words to bring a moment of peace and mindfulness to your day. The messages provide a quick insight, motivation, or uplifting shift of energy at any time.`,
  FAM: `These 52 cards teach the language of self-esteem and integrity while nurturing family unity. Each card offers inspirational multi-faith quotes, descriptions of each virtue, and signs of success. These cards are perfect for character building for the whole family.`,
  EDU: `The 52 Virtues Education Cards are a kid-friendly tool used by schools across the globe to strengthen school culture and climate, and to enhance Social Emotional Learning (SEL), character education resilience, mindfulness and restorative practice programs. Useful in the classroom, counseling sessions, and sharing circles.`,
  CHA: "Character is the way we think, feel, and behave. This set of 120 contemplative cards features moral and performance qualities for use in daily reflection, guidance and practice. Good for leadership, personal, and organizational development. These cards have no reference to religion.",
  DEC: `The  DECA Cards were designed as a partnership between Virtues Matters and Washington DECA. DECA students created all images, selected quotes and modified the text from The Virtues Project Reflection Cards.`,
};

export const CARD_DECK_NAME = {
  REF: `Virtues Reflection Cards`,
  SUN: `Virtues Resilience Cards`,
  FAM: "Virtues Family Cards",
  EDU: "Virtues Education Cards",
  CHA: "Virtues Character Cards",
  SAM: "Virtues Sample Cards",
  DEC: "Virtues DECA Cards",
};

export const CARD_TYPE_DESC = {
  REF: "reflection",
  SUN: "sunset",
  FAM: "family",
  EDU: "education",
  CHA: "CHA",
  DEC: "DECA",
};

export const CARD_SWIPE_DIRECTIONS = {
  SWIPE_UP: "SWIPE_UP",
  SWIPE_DOWN: "SWIPE_DOWN",
  SWIPE_LEFT: "SWIPE_LEFT",
  SWIPE_RIGHT: "SWIPE_RIGHT",
};

export const DECK_DOWNLOAD_URL = {
  EDU: "https://s3.amazonaws.com/virtuecards/packages/edu.zip",
  FAM: "https://s3.amazonaws.com/virtuecards/packages/fam.zip",
  REF: "https://s3.amazonaws.com/virtuecards/packages/ref.zip",
  SUN: "https://s3.amazonaws.com/virtuecards/packages/sun.zip",
  CHA: "https://virtuecards.s3.amazonaws.com/processed/packages/cha.zip",
  SAM: "https://s3.amazonaws.com/virtuecards/packages/sam2.zip",
  DEC: "https://s3.amazonaws.com/virtuecards/packages/dec2.zip",

  // this is the third photo when you share a card
  //SHARE_IMAGES: 'https://virtuecards.s3.amazonaws.com/processed/packages/DeckShareImages.zip'
  //we replace this direction to achieve the third panel upload
  SHARE_IMAGES: "https://s3.amazonaws.com/virtuecards/packages/deck/",

  // EDU : 'https://virtuecards.s3.amazonaws.com/processed/packages/test/edu.zip',
  // FAM : 'https://virtuecards.s3.amazonaws.com/processed/packages/test/fam.zip',
  // REF : 'https://virtuecards.s3.amazonaws.com/processed/packages/test/ref.zip',
  // SUN : 'https://virtuecards.s3.amazonaws.com/processed/packages/test/sun.zip',
};

export const FILTER = {
  FAVE: "FAVE",
  CHECKED: "CHECKED",
  NONE: "",
};

export const FRIEND_REQUEST_STATUS = {
  ACT: "ACT",
  ACC: "ACC",
  DEC: "DEC",
  CAN: "CAN",
};

export const JOURNAL_SUBMIT_MODE = {
  ADD: "ADD",
  UPD: "UPD",
};

export const SCREEN_ORIENTATION = {
  LANDSCAPE: "LANDSCAPE",
  PORTRAIT: "PORTRAIT",
};

export const SOCIAL_LOGIN_TYPE = {
  FB: "fb",
  GOOGLE: "google",
  APPLE: "apple",
};

export const SEARCH_TIMING_TIMEOUT = {
  VALUE: 400,
};

export const DOUBLE_PRESS_DELAY = 500;

export const UPDATE_MODE = {
  EMAIL: "email",
  PASSWORD: "password",
  QUOTE: "quote",
  MEDIA_PROFILE: "media_profile",
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  PROFILE_DESC: "profile_description",
  FAVE: "is_fave",
  READ: "is_read",
  USER_DETAILS: "user_details",
};

// export default Constants;

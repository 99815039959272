import * as React from "react";
import { TextField, Box, AppBar } from "@mui/material";
import { useDispatch } from "react-redux";
import { setSearchParam } from "../../features/cards/cardsSlice";

export default function SearchBar() {
  const dispatch = useDispatch(); 
  const handleChange = (event) => {
    dispatch(setSearchParam(event.target.value));
  }


  return ( 
  <Box sx={{ width: '100%', height: '25px', marginBottom: '10px', transition: '0.5s' }}>
  <AppBar position="static" sx={{ backgroundColor: 'whitesmoke', boxShadow: 'none' }}>
    <TextField
      id="outlined-basic"
      label=""
      variant="outlined"
      placeholder="Search Card by Title"
      size="small"
      inputProps={{
        style: { fontSize: '0.9rem', fontWeight: 'lighter', paddingLeft: '20px' },
      }}
      onChange={handleChange}
    />
  </AppBar>
</Box>
);
}

import { Typography } from "@mui/material";
import { uiConstants } from "../../utils";

const SectionTitle = ({ textColor, titleText }) => {
  return (
    <Typography
      variant="h6"
      className="deckTitle"
      sx={{
        alignSelf: "flex-start",
        marginBottom: "2px",
        color: textColor,
        fontWeight: "bold",
        fontSize: "1.8rem",
      }}
    >
      {titleText}
    </Typography>
  );
};

export default SectionTitle;

import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import vp_logo from "../assets/images/vp_wide.png" 
import { uiConstants } from "../utils/constants";
import { updateUserProfile } from "../logic/Requests";
import { loginUser } from "../features/user/userSlice";
import { useNavigate } from "react-router-dom";
import { openSnackbar } from "../features/ui/uiSlice";

const AccountPage = () => {
  const user = useSelector((state) => state.userState.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [email, setEmail] = useState(user.email);

  const isDisabled =
    firstName === user.first_name &&
    lastName === user.last_name &&
    email === user.email;

  const handleSave = async () => {
    try {
      const response = await updateUserProfile({
        firstName,
        lastName,
        email,
        userId: user.user_id,
        token: user.token,
      });
      const { token, user_id, first_name, last_name } = response.user;
      dispatch(loginUser({ token, user_id, first_name, last_name, email }));
      dispatch(openSnackbar({message: "User Updated successfully!", type: "success"}))
    } catch (error) {
      console.error(error);
      dispatch(openSnackbar({message: "Something went wrong, please try again", type: "error"}))
    }
  };

  const handleResetPassword = () => {
    navigate("/app/reset-password");
  };

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "20px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "400px",
          minWidth: "300px",
          textAlign: "center",
          padding: "0 20px",
          marginTop: "30px",
        }}
      >
        <img
          src={vp_logo}
          alt="Virtues Cards Logo"
          style={{ width: "80%", marginBottom: "10px", objectFit: "cover" }}
        />
        <Typography variant="h6">v2.0.2</Typography>

        <TextField
          label="First name"
          variant="outlined"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Last name"
          variant="outlined"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
        />

        <Button
          variant="contained"
          onClick={handleSave}
          fullWidth
          disabled={isDisabled}
          sx={{
            marginTop: "20px",
            marginBottom: "20px",
            backgroundColor: uiConstants.PURPLE,
            color: "white",
            textTransform: "none",
            borderRadius: "20px",
            "&:hover": { backgroundColor: "#A0A0A0" },
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          onClick={handleResetPassword}
          fullWidth
          sx={{
            backgroundColor: uiConstants.PURPLE,
            color: "white",
            textTransform: "none",
            borderRadius: "20px",
            "&:hover": { backgroundColor: "#d81b60" },
          }}
        >
          Reset Password
        </Button>
      </Box>
    </Box>
  );
};

export default AccountPage;

import { createTheme } from '@mui/material/styles';
import { uiConstants } from '../../utils/constants'; // Update with your constants file path

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: "20px",
          backgroundColor: uiConstants.BLUE,
          width: "100%",
          maxWidth: "600px",
        },
        indicator: {
          backgroundColor: uiConstants.PURPLE,
          height: "5px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: uiConstants.GREY,
          '&.Mui-selected': {
            color: 'white',
          },
        },
      },
    },
  },
});

export default theme;
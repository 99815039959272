import { Button } from "@mui/material";
import { uiConstants } from "../../utils/constants";

const btnSizes = {
  large: "300px",
  medium: "218px",
  small: "160px",
}
const SmallBtn = ({ text, size, btnType, onClickFn }) => {
  return (
    <Button
    type={btnType}
    onClick={onClickFn}
    variant="contained"
    color="primary"
    className="button"
    fullWidth
    sx={{
      width: btnSizes[size],
      margin: "auto",
      borderRadius: "20px",
      backgroundColor: uiConstants.PURPLE, // Pink color
      color: "white",
      "&:hover": {
        backgroundColor: uiConstants.DARK_PURPLE, // Darker pink on hover
      },
      marginBottom: "30px",
    }}
  >
    {text}
  </Button>
  )
}

export default SmallBtn
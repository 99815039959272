import React from 'react'
import { FaPencilAlt } from "react-icons/fa";
import { uiConstants } from "../../utils/constants";
import { Box } from '@mui/material';

const JournalPencil = ({ handleClick }) => {
  return (
    <Box
    sx={{
      position: "absolute",
      bottom: "120px",
      right: "30px",
      backgroundColor: uiConstants.BLUE,
      padding: "14px",
      borderRadius: "50%",
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      cursor: "pointer",
      zIndex: "999",
    }}
    onClick={handleClick}
  >
    <FaPencilAlt style={{ fontSize: "30px", color: "white" }} />
  </Box>
  )
}

export default JournalPencil
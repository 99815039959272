import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button, Typography, Box } from "@mui/material";
import vp_intro_logo1 from "../assets/images/vp_intro_logo1.png";
import vp_intro_logo2 from "../assets/images/vp_intro_logo2.png";
import plantIcon from "../assets/images/plant.png";
import deckIcon from "../assets/images/decks.png";
import clockIcon from "../assets/images/clock.png";
import "../assets/styles/CarouselComponent.css";
import { uiConstants } from "../utils/constants";

const CarouselComponent = () => {
  const navigate = useNavigate();
  const carouselRef = useRef(null);

  const nextSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.onClickNext();
    }
  };

  const goToSignup = () => {
    localStorage.setItem("hasVisited", "true");
    navigate("/signup");
  };

  const buttonStyles = {
    width: "100%",
    borderRadius: "20px",
    color: "white",
    borderColor: "white",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)", // Light white background on hover
      borderColor: "white",
    },
    marginBottom: "40px",
  };

  const lastSlideButtonStyles = {
    width: "100%",
    borderRadius: "20px",
    backgroundColor: uiConstants.PURPLE, // Pink color
    color: "white",
    "&:hover": {
      backgroundColor: "#d81b60", // Darker pink on hover
    },
    marginBottom: "40px",
  };

  return (
    <Carousel
      showThumbs={false}
      infiniteLoop
      useKeyboardArrows={false}
      showArrows={false}
      ref={carouselRef}
      showStatus={false}
    >
      <div className="slide container">
        <Box className="text-overlay top">
          <Typography variant="h3" className="title" sx={{ mt: "4vh" }}>
            Welcome
          </Typography>
          <Typography variant="h5" className="subtitle">
            to the Virtues Cards app!
          </Typography>
        </Box>
        <Box className="text-overlay middle">
          <Typography variant="h6" className="subtitle">
            A collaboration between
          </Typography>
          <Box className="logo-container">
            <img
              src={vp_intro_logo1}
              alt="Virtues Matter Logo"
              className="logo"
            />
            <Typography variant="h6" className="subtitle">
              and
            </Typography>
            <img
              src={vp_intro_logo2}
              alt="Virtues Project Logo"
              className="logo"
            />
          </Box>
        </Box>
        <Box className="text-overlay bottom">
          <Button variant="outlined" onClick={nextSlide} sx={buttonStyles}>
            Next
          </Button>
        </Box>
      </div>
      <div className="slide container" style={{justifyContent: "space-between"}}>
        <Box className="text-overlay-left">
          <img
            src={plantIcon}
            alt="Plant Icon"
            style={{
              width: "70px",
              height: "70px",
              marginBottom: "30px",
              marginTop: "50px",
            }}
          />
          <Typography variant="h4" className="title">
            Cultivate Virtues
          </Typography>
          <Typography variant="body1" className="subtitle">
            We honor your commitment to cultivate 'virtues'—those universal
            positive qualities of character that bring out the best in ourselves
            and others.
          </Typography>
        </Box>
        <Box className="text-overlay bottom">
          <Button variant="outlined" onClick={nextSlide} sx={buttonStyles}>
            Next
          </Button>
        </Box>
      </div>
      <div className="slide container" style={{justifyContent: "space-between"}}>
        <Box className="text-overlay-left">
          <img
            src={deckIcon}
            alt="Deck Icon"
            style={{
              width: "70px",
              height: "70px",
              marginBottom: "30px",
              marginTop: "50px",
            }}
          />
          <Typography variant="h4" className="title">
            Sample Deck
          </Typography>
          <Typography variant="body1" className="subtitle">
            On your home screen, you’ll find a free Sample Deck. We selected
            popular cards from several decks so that you can get started right
            away. To preview and purchase the full decks, simply visit the Shop.
          </Typography>
        </Box>
        <Box className="text-overlay bottom">
          <Button variant="contained" onClick={goToSignup} sx={lastSlideButtonStyles}>
          Get Started
          </Button>
        </Box>
      </div>
      {/*<div className="slide container" style={{justifyContent: "space-between"}}>
        <Box className="text-overlay-left">
          <img
            src={clockIcon}
            alt="Clock Icon"
            style={{
              width: "70px",
              height: "70px",
              marginBottom: "30px",
              marginTop: "50px",
            }}
          />
          <Typography variant="h4" className="title">
            Set a Reminder
          </Typography>
          <Typography variant="body1" className="subtitle">
            Visit the menu to set a Daily Virtues Pick reminder, and check out
            the Tutorial to learn about other features such as shaking your
            phone for a random virtue, or double-tapping to view the back of the
            card.
          </Typography>
        </Box>
        <Box className="text-overlay bottom">
          <Button
            variant="contained"
            onClick={goToSignup}
            sx={lastSlideButtonStyles}
          >
            Get Started
          </Button>
        </Box>
      </div>*/}
    </Carousel>
  );
};

export default CarouselComponent;

import { Paper, Typography } from "@mui/material";

function CheckOutTitle() {
  return (
    <Paper
    elevation={3}
    sx={{
      backgroundColor: "#DA70D6",
      borderRadius: "36px",
      color: "white",
      width: "80%",
      margin: "0 auto",
      marginBottom: "10px",
    }}
  >
    <Typography
      sx={{
        marginTop: "10px",
        padding: "4px",
        borderRadius: "10px",
        fontSize: "18px",
        fontStyle: "italic",
        display: "inline-block",
      }}
      className="checkoutTitle"
      >
      Awaken your spirit & live by your deepest values
    </Typography>
  </Paper>
  )
}

export default CheckOutTitle
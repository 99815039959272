import { Typography, Button, Link } from "@mui/material";
import { uiConstants } from "../../utils/constants";


const TutorialPage = () => {
  return (
    <>
    <Typography >
        The Virtues Cards app is a powerful tool for personal development & growth. Just like any tool, it's important to know how to use it so that you can get the most benefit.
      </Typography>
      <Typography  sx={{ mb: "50px"}}>
        We've created a short tutorial video to go over the key features of the app, and practical tips for integrating it into your daily life so that you can find more joy, meaning, and purpose.
      </Typography>
      <Link href="https://www.virtuesmatter.org/blog/virtues-cards-tutorial" sx={{ display: "inline-block", width: "min-content", height: "min-content" }}>
      <Button
          type="submit"
          variant="contained"
          color="primary"
          className="button"
          fullWidth
          sx={{
            width: "160px",
            borderRadius: "20px",
            backgroundColor: uiConstants.PURPLE, // Pink color
            color: "white",
            "&:hover": {
              backgroundColor: uiConstants.DARK_PURPLE, // Darker pink on hover
            },
          }}
        >
          Watch Video
        </Button>
        </Link>
    </>
  )
}

export default TutorialPage
import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  isSearchBarOpen: false,
  isCardFrontSide: true,
  isCardOpen: false,
  snackBarProps: {
    openSnackbar: false,
    message: "",
    type: ""
  },
  hasOpenedCard: false,
  isCompactCards: true
};
const getUiFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("ui")) || defaultState;
};

const uiSlice = createSlice({
  name: "uiState",
  initialState: getUiFromLocalStorage,
  reducers: {

    // ======> LIST CARDS <======
    listCards: (state) => {
      state.isListCards = true;
      state.isCompactCards = false
      localStorage.setItem("ui", JSON.stringify(state));
    },

    // ======> COMPACT CARDS <======
    compactCards: (state) => {
      state.isCompactCards = true;
      state.isListCards = false
      localStorage.setItem("ui", JSON.stringify(state));
    },

    // ======> SEARCH BAR <======
    toggleSearchBar: (state) => {
      state.isSearchBarOpen = !state.isSearchBarOpen;
      localStorage.setItem("ui", JSON.stringify(state));
    },

    // ======> CARD <======
    toggleCardSide: (state) => {
      state.isCardFrontSide = !state.isCardFrontSide;
      localStorage.setItem("ui", JSON.stringify(state));
    },
    setCardFrontSide: (state) => {
      state.isCardFrontSide = true;
      localStorage.setItem("ui", JSON.stringify(state));
    },
    openCard: (state) => {
      state.isCardOpen = true;
      localStorage.setItem("ui", JSON.stringify(state));
    },
    closeCard: (state) => {
      state.isCardOpen = false;
      localStorage.setItem("ui", JSON.stringify(state));
    },

    setHasOpenedCard: (state) => {
      state.hasOpenedCard = true;
      localStorage.setItem("ui", JSON.stringify(state));
    },

    // ======> SNACKBAR <======
    openSnackbar: (state, action) => {
      state.snackBarProps = {
        openSnackbar: true,
        message: action.payload.message,
        type: action.payload.type

      };
      localStorage.setItem("ui", JSON.stringify(state));
    },
    closeSnackbar: (state) => {
      state.snackBarProps = {
        openSnackbar: false,
        message: "",
        type: ""
      };
      localStorage.setItem("ui", JSON.stringify(state));
    },

    // ======> GENERAL <======
    resetUiState: (state) => {
      state = defaultState;
      localStorage.setItem("ui", JSON.stringify(state));
    }

  },
});

export const {
  closeCard,
  closeSnackbar,
  openCard,
  openSnackbar,
  resetUiState,
  setCardFrontSide,
  setHasOpenedCard,
  toggleCardSide,
  toggleSearchBar,
  listCards,
  compactCards
} = uiSlice.actions;

export default uiSlice.reducer;

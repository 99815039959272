import { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { uiConstants } from "../../utils/constants";
import { useSelector, useDispatch } from "react-redux";
import { redeemCode, addDeckToUser, queryClient } from "../../utils";
import { openSnackbar } from "../../features/ui/uiSlice";
import {
  CARD_DECK_NAME,
  DECK_PURCHASE_CARD_TITLE,
} from "../../utils/constants";

import {
  userCardsQuery,
  userDecksQuery,
  userSampleCardsQuery,
} from "../../utils";

import {
  setAllCards,
  setPurchasedDecks,
  setSelectedDeck,
} from "../../features/cards/cardsSlice";

import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const RedeemCodesPage = () => {
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userState.user);
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: addDeckToUser,
  });

  const handleRedeem = async () => {
    setIsLoading(true);
    try {
      const response = await redeemCode(user.email, code);
      const redemption = response.codeRedemptionStatus;

      if (redemption.validCode && redemption.message === "The code is valid") {
        await mutation.mutateAsync({
          userId: user.user_id,
          deckCode: redemption.deckCode,
        });

        const userCardsResponse = await queryClient.fetchQuery(
          userCardsQuery(user.user_id, "EN")
        );
        const userDecksResponse = await queryClient.fetchQuery(
          userDecksQuery(user.user_id, "EN")
        );
        const userSampleCardsResponse = await queryClient.fetchQuery(
          userSampleCardsQuery(user.user_id, "EN")
        );

        const purchasedDeckCodes = new Set(
          userDecksResponse.userDecks.map((deck) => deck.deck_code)
        );

        const filteredUserCards = userCardsResponse.cards.filter((card) =>
          purchasedDeckCodes.has(card.card_deck_code)
        );
        const filteredExampleCards =
          userSampleCardsResponse.samUserCards.filter(
            (card) => !purchasedDeckCodes.has(card.card_deck_code)
          );

        const resultCards = [...filteredUserCards, ...filteredExampleCards];
        const uniqueResultCards = Array.from(
          new Map(resultCards.map((card) => [card.card_id, card])).values()
        );

        dispatch(setPurchasedDecks(userDecksResponse.userDecks));
        dispatch(setAllCards(uniqueResultCards)); // <- esto setea actualDeck según el selectedDeck actual
        dispatch(
          setSelectedDeck(DECK_PURCHASE_CARD_TITLE[redemption.deckCode])
        );

        dispatch(
          openSnackbar({
            message: `Code redeemed successfully! ${
              CARD_DECK_NAME[redemption.deckCode]
            } added to your account`,
            type: "success",
          })
        );

        setCode("");
        setIsLoading(false);

        window.location.replace(`/app/home?deck=${redemption.deckCode}`);
        return;
      }

      dispatch(
        openSnackbar({
          message: redemption.message,
          type: "error",
        })
      );
    } catch (error) {
      console.error("ERROR:", error);
      dispatch(
        openSnackbar({
          message: "Something went wrong, please try again",
          type: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "10px",
        paddingBottom: "20px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "400px",
          minWidth: "300px",
          textAlign: "center",
          padding: "0 20px",
        }}
      >
        <Typography sx={{ textAlign: "left" }}>
          Enter your redemption code!
        </Typography>

        <TextField
          label="Code"
          variant="outlined"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          fullWidth
          margin="normal"
        />

        <Button
          variant="contained"
          onClick={handleRedeem}
          disabled={isLoading}
          sx={{
            marginTop: "20px",
            marginBottom: "20px",
            backgroundColor: uiConstants.PURPLE,
            color: "white",
            textTransform: "none",
            borderRadius: "20px",
            width: "218px",
            height: "38px",
            "&:hover": { backgroundColor: "#A0A0A0" },
          }}
        >
          {isLoading ? <CircularProgress size={20} /> : "Redeem Code!"}
        </Button>
      </Box>
    </Box>
  );
};

export default RedeemCodesPage;

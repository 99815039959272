import { useState } from "react";
import { Box } from "@mui/material";
import {
  checkoutPayment,
  DECK_PRICE,
  DECK_SHOP_DESCRIPTION,
  DECK_PRICE_ID,
} from "../../utils";
import { useSelector } from "react-redux";
import { useLoaderData, useParams } from "react-router-dom";
import {
  CheckOutPreviewList,
  CheckOutTitle,
  CheckOutPrice,
  CheckOutBtn,
} from "../../components";

export const loader =
  () =>
  async ({ request }) => {
    const deckType = new URL(request.url).pathname.split("/")[3];

    return { deckType };
  };

const CheckoutPage = () => {
  const completeDecks = useSelector((state) => state.cardsState.completeDecks);
  const { deckType } = useLoaderData();
  const description = DECK_SHOP_DESCRIPTION[deckType];
  const price = DECK_PRICE[deckType];
  const deckCards = completeDecks[deckType];
  const priceId = DECK_PRICE_ID[deckType];

  const { deckCode } = useParams();
  const [showingPreview, setShowingPreview] = useState(false);
  const user = useSelector((state) => state.userState.user);

  const handleShowPreview = () => {
    setShowingPreview(!showingPreview);
  };

  const handleSubmit = async () => {
    try {
      await checkoutPayment({
        userId: user.user_id,
        deckCode,
        priceId,
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: "20px 0 ",
        color: "black",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div style={{width: "100%"}} className="checkoutContainer">
        <CheckOutTitle />
        <CheckOutPrice description={description} price={price} />
        <CheckOutBtn handleSubmit={handleSubmit} />
      </div>

      <CheckOutPreviewList
        deckCards={deckCards}
        showingPreview={showingPreview}
        handleShowPreview={handleShowPreview}
      />
    </Box>
  );
};

export default CheckoutPage;

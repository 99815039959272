import React from "react";
import { Box, Typography, Grid, IconButton, Link } from "@mui/material";
import virtuesMatterLogo from "../../assets/images/virtues_matter_logo.png";
import theVirtuesProjectLogo from "../../assets/images/vp_gold.png";
import shareTreeLogo from "../../assets/images/share_tree_logo.png";
import { VirtuesBtn } from "../../components";
import { LuInstagram } from "react-icons/lu";
import { FaXTwitter } from "react-icons/fa6";
import { LuFacebook } from "react-icons/lu";

const AboutUsPage = () => {
  return (
    <Box
      sx={{
        padding: "20px 0 100px",
        fontSize: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
      className="text-container"
    >
      <Typography variant="body1">
        The Virtues Cards App was developed by Virtues Matter in partnership
        with The Virtues Project™, working closely together to bring the power
        of virtues to people around the world.
      </Typography>

      <Box
        component="img"
        src={virtuesMatterLogo}
        alt="VM Logo"
        sx={{
          width: "60%",
          marginBottom: "35px",
          alignSelf: "center",
          marginTop: "20px",
        }}
      />

      <Typography
        variant="h3"
        sx={{ fontWeight: "bold", fontSize: "18px", marginTop: "20px" }}
      >
        About Virtues Matter
      </Typography>
      <Typography variant="body1">
        We are on a mission to uplift humanity by inspiring transformation in
        individuals, families, organizations, and communities. In addition to
        the Virtues Cards mobile app, Virtues Matter offers workshops, online
        courses, coaching, and consultative services that help cultivate
        positive qualities of character. Join us!
      </Typography>

      <Box sx={{ textAlign: "center", margin: "30px 0 40px" }}>
        <VirtuesBtn
          text={"Learn More"}
          size={"small"}
          btnType={"button"}
          onClickFn={() =>
            (window.location.href = "https://www.virtuesmatter.org/")
          }
        />
      </Box>

      <Box
        component="img"
        src={theVirtuesProjectLogo}
        alt="VP Gold Logo"
        sx={{ width: "70%", marginBottom: "35px", alignSelf: "center" }}
      />

      <Typography
        variant="h3"
        sx={{ fontWeight: "bold", fontSize: "18px", marginTop: "20px" }}
      >
        About The Virtues Project™
      </Typography>
      <Typography variant="body1">
        The cards were researched and created by Linda Kavelin-Popov and Dr. Dan
        Popov, founders of The Virtues Project.
      </Typography>
      <Typography variant="body1">
        Initiated in 1991, the Project’s mission was to help children and youth
        develop authentic self-esteem and encourage their practice of virtues in
        everyday life. It quickly expanded as a global movement for people of
        all ages and was honored by the United Nations during the International
        Year of the Family as a “model global program for families of all
        cultures.” It has also been proudly endorsed by the Dalai Lama.
      </Typography>
      <Typography variant="body1">
        Virtues Project International Association (VPIA) is a Canadian
        non-profit/non-governmental organization that sustains the integrity and
        the growth of the Virtues Project.
      </Typography>

      <Box sx={{ textAlign: "center", margin: "30px 0 40px" }}>
        <VirtuesBtn
          text={"Learn More"}
          size={"small"}
          btnType={"button"}
          onClickFn={() =>
            (window.location.href = "https://www.virtuesproject.org")
          }
        />
      </Box>

      <Box
        component="img"
        src={shareTreeLogo}
        alt="ShareTree Logo"
        sx={{ width: "60%", marginBottom: "35px", alignSelf: "center" }}
      />

      <Typography
        variant="h3"
        sx={{ fontWeight: "bold", fontSize: "18px", marginTop: "20px" }}
      >
        About ShareTree Inc.
      </Typography>
      <Typography variant="body1">
        ShareTree integrated the original 100 Reflection Cards from The Virtues
        Project and added 20 new cards to create the Character Cards, a secular
        non-religious set that can be used in any aspect of life for businesses,
        community organizations, schools, families, and coaching.
      </Typography>
      <Typography variant="body1">
        ShareTree has its own mobile app, analysis tools and reporting for
        character development and team culture enhancement to improve well-being
        and performance.
      </Typography>

      <Box sx={{ textAlign: "center", margin: "30px 0 40px" }}>
        <VirtuesBtn
          text={"Learn More"}
          size={"small"}
          btnType={"button"}
          onClickFn={() => (window.location.href = "https://www.sharetree.org")}
        />
      </Box>

      <Box
        sx={{
          padding: "20px 0",
          fontSize: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
        className="text-container"
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", fontSize: "18px", marginTop: "20px" }}
        >
          Contact Us
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Typography variant="body1">We'd love to hear from you!</Typography>
          <Typography variant="body1">
            If you have any questions, comments, or feedback, please email{" "}
            <Link href="mailto:service@virtuesmatter.org" underline="none">
              service@virtuesmatter.org
            </Link>
            .
          </Typography>
          <Typography variant="body1">
            To learn more about how we can serve you, visit{" "}
            <Link href="https://www.virtuesmatter.org/" underline="none">
              virtuesmatter.org
            </Link>
            .
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ marginTop: "20px" }}>
          Be sure to follow us on social media for regular inspiration and
          updates!
        </Typography>
        <Grid container spacing={2} sx={{ marginTop: "14px" }}>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              href="https://www.facebook.com/virtuesmatter"
              target="_blank"
              color="primary"
              aria-label="Facebook"
              sx={{ border: 2, borderRadius: "3rem", padding: "0.5rem" }}
            >
              <LuFacebook />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              href="https://www.twitter.com/virtuesmatter"
              target="_blank"
              color="primary"
              aria-label="Twitter"
              sx={{ border: 2, borderRadius: "3rem", padding: "0.5rem" }}
            >
              <FaXTwitter />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              href="https://www.instagram.com/virtuesmatter"
              target="_blank"
              color="primary"
              aria-label="Instagram"
              sx={{ border: 2, borderRadius: "3rem", padding: "0.5rem" }}
            >
              <LuInstagram />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AboutUsPage;

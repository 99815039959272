import React from "react";
import { Container } from "@mui/material";
import "../../assets/styles/Shop.css";
import { deckTypes, CARD_TYPES, trimArrays } from "../../utils";
import { ShopGrid } from "../../components";
import { useLoaderData, useNavigate } from "react-router-dom";
import { allCardsByDeckQuery } from "../../utils";
import { setCompleteDecks, setPurchasedDecks } from "../../features/cards/cardsSlice";
import { openSnackbar } from "../../features/ui/uiSlice";
import { userDecksQuery } from "../../utils";

export const loader =
  (store, queryClient) =>
  async ({ request }) => {
    let success = "false";
    let userPurchasedDecks = [];
    let completeDecks = store.getState().cardsState.completeDecks;
    try {
      const url = new URL(request.url);
      const queryParams = new URLSearchParams(url.search);
      success = queryParams.get("success");
      if (success === "true") {
        const userId = store.getState().userState.user.user_id;
        await queryClient.invalidateQueries({
          queryKey: ["userDecks", userId, "EN"],
        });
        const userDecksResponse = await queryClient.ensureQueryData(
          userDecksQuery(userId, "EN")
        );
        userPurchasedDecks = userDecksResponse.userDecks;
        store.dispatch(setPurchasedDecks(userPurchasedDecks));
        store.dispatch(openSnackbar({message: "Deck purchased successfully!", type: "success"}))
        userPurchasedDecks = userPurchasedDecks.map((deck) => deck.deck_code);
      }
      else{
        userPurchasedDecks = store.getState().cardsState.purchasedDecks;
      }
    } catch (error) {
      console.error("Something went wrong, please reload the page");
    }

    try {
      const requests = CARD_TYPES.map(async (cardType) => {
        const userCardsResponse = await queryClient.ensureQueryData(
          allCardsByDeckQuery(cardType, "EN")
        );
        return { deck_code: cardType, cards: userCardsResponse };
      });
      
      const results = await Promise.all(requests);
      
      // Dispatch actions for all completed requests
      results.forEach(({ deck_code, cards }) => {
        store.dispatch(setCompleteDecks({ deck_code, cards }));
      });
    } catch (error) {
      console.error("Something went wrong, please reload the page");
    }
    return { success, userPurchasedDecks, completeDecks: store.getState().cardsState.completeDecks } ;
  };

const ShopPage = () => {
  const navigate = useNavigate();
  let { userPurchasedDecks, completeDecks }= useLoaderData()


  const purchasedDecks = deckTypes.filter((deck) =>
    userPurchasedDecks.includes(deck.code)
  );
  const nonPurchasedDecks = deckTypes.filter(
    (deck) => !userPurchasedDecks.includes(deck.code)
  );
  completeDecks = trimArrays(completeDecks, 3);

  const handleNonPurchasedClick = (deck) => {
    navigate("/app/shop-checkout/" + deck.code);
  };

  const handlePurchasedClick = (deck) => {
    navigate("/app/home?deck=" + deck.code);
  };

  return (
    <Container
      className="shop-cards-container"
      sx={{
        backgroundColor: "white",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px 20px 100px",
        overflow: "auto",
      }}
    >
      <ShopGrid
        deck={nonPurchasedDecks}
        title={"Shop More Decks"}
        onClickFn={handleNonPurchasedClick}
        completeDecks={completeDecks}
      />
      <ShopGrid
        deck={purchasedDecks}
        title={purchasedDecks.length === 1 ? "My Deck" : "My Decks"}
        onClickFn={handlePurchasedClick}
        completeDecks={completeDecks}
      />
    </Container>
  );
};

export default ShopPage;

import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const SnackbarComponent = ({
  openSnackbar,
  handleCloseSnackbar,
  message,
  type,
}) => {
  return (
    <Snackbar
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      width="100%"
      sx={{ zIndex: 10 }}
    >
      <MuiAlert
        onClose={handleCloseSnackbar}
        severity={type}
        color={type}
        sx={{ width: "300px" }}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default SnackbarComponent;

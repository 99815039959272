import React from "react";
import { ListItem, IconButton, Typography, Box } from "@mui/material";
import { CropSquare as CropSquareIcon } from "@mui/icons-material";
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import { useDispatch, useSelector } from "react-redux";
import { setFilterToCards } from "../../features/cards/cardsSlice";

const FilterCardItem = ({ icon, filter, text, checked, iconColor }) => {
  const dispatch = useDispatch();
  const filteredBy = useSelector((state) => state.cardsState.areCardsFiltered);
  const isThisFilterEnabled = filteredBy === filter
  const handleClick = () => {
    dispatch(setFilterToCards(filter));
  }
  return (
    <ListItem
      button
      sx={{
        color: "#B9B9B9",
        cursor: "pointer",
        width: "90%",
        margin: "0 auto",
        borderBottom: "1px solid whiteSmoke",
        display: "flex",
        justifyContent: "space-between",
      }}

    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton aria-label="check" sx={{ color: iconColor }}>
          {icon}
        </IconButton>
        <Typography variant="h6" component="h2">
          {text}
        </Typography>
      </Box>
      <IconButton aria-label="check" onClick={handleClick}>
        {isThisFilterEnabled ? <LibraryAddCheckIcon sx={{ color: "green" }} /> : <CropSquareIcon />}
      </IconButton>
    </ListItem>
  );
};

export default FilterCardItem;
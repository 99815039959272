import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Typography,
  IconButton,
  Box,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { addSampleCardsToUser, registerUser } from "../logic/Requests";
import { useDispatch } from "react-redux";
import { loginUser } from "../features/user/userSlice";
import { uiConstants, recaptchaKey } from "../utils";
import { resetUiState } from "../features/ui/uiSlice";
import { GoogleReCaptcha } from "../components";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";


const EmailSignUpPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  dispatch(resetUiState())
  

  const isDisabled = !firstName || !lastName || !email || !password;

  const handleBack = () => {
    navigate(-1);
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const userData = { firstName, lastName, email, password };
      const response = await registerUser(userData);
      if (response.status.code !== "0") {
        setError(
          response.status.description ||
            "Registration failed. Please try again."
        );
        setOpenSnackbar(true);
        return;
      }
      const sampleCardsResponse = await addSampleCardsToUser(
        response.user.user_id
      )
      if ( sampleCardsResponse.status.code !== "0") {
        setError(
          sampleCardsResponse.status.description ||
            "Sample cards registration failed. Please contact support."
        );
        setOpenSnackbar(true);
        return;
      }


      const { token, user_id, first_name,last_name} = response.user;
      dispatch(loginUser({ token, user_id, first_name, last_name, email }));
      navigate("/app/home");
    } catch (error) {
      setError(error.message || "Registration failed. Please try again.");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const textFieldStyles = {
    "& .MuiInput-underline:before": { borderBottomColor: "white" },
    "& .MuiInput-underline:after": { borderBottomColor: "white" },
    "& .MuiInputBase-input": { color: "white" },
    "& .MuiInputLabel-root": { color: "white" },
    width: "100%",
    maxWidth: "100%",
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
    <Box
      className="container"
      sx={{
        backgroundColor: "#1E1E2C",
        color: "white",
        justifyContent: "flex-start",
        padding: "30px 40px",
      }}
    >
      <div
        style={{
          marginBottom: "3rem",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <IconButton
          onClick={handleBack}
          sx={{
            color: "white",
            alignSelf: "flex-start",
            marginBottom: "20px",
            padding: 0,
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h4"
          className="title"
          sx={{ marginBottom: "20px", textAlign: "left" }}
        >
          Create an Account
        </Typography>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </MuiAlert>
      </Snackbar>

      <form noValidate onSubmit={handleSubmit}>
        <TextField
          variant="standard"
          type="text"
          placeholder="First Name*"
          className="input"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
          fullWidth
          margin="normal"
          sx={textFieldStyles}
        />
        <TextField
          variant="standard"
          type="text"
          placeholder="Last Name*"
          className="input"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
          fullWidth
          margin="normal"
          sx={textFieldStyles}
        />
        <TextField
          variant="standard"
          type="email"
          placeholder="Email*"
          className="input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          fullWidth
          margin="normal"
          sx={textFieldStyles}
        />
        <Box
          className="password-container"
          sx={{ position: "relative", marginBottom: "30px", width: "100%" }}
        >
          <TextField
            variant="standard"
            type={showPassword ? "text" : "password"}
            placeholder="Password*"
            className="input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            fullWidth
            margin="normal"
            sx={textFieldStyles}
          />
          <IconButton
            onClick={handleTogglePasswordVisibility}
            sx={{
              position: "absolute",
              right: "0",
              top: "30%",
              color: "white",
            }}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Box>
        <GoogleReCaptcha btnComponent={
                  <Button
                  type="submit"
                  variant="contained"
                  className="submit-button email-button button"
                  disabled={isDisabled}
                  fullWidth
                  sx={{
                    width: "218px",
                    margin: "auto",
                    borderRadius: "20px",
                    backgroundColor: uiConstants.PURPLE, 
                    color: "white",
                    "&:hover": {
                      backgroundColor: uiConstants.DARK_PURPLE,
                    },
                    marginBottom: "30px",
                    marginTop: "2rem",
                  }}
                >
                  Let's Go
                </Button>
        } />
        {/* <Button
          type="submit"
          variant="contained"
          className="submit-button email-button button"
          disabled={isDisabled}
          fullWidth
          sx={{
            width: "218px",
            margin: "auto",
            borderRadius: "20px",
            backgroundColor: uiConstants.PURPLE, 
            color: "white",
            "&:hover": {
              backgroundColor: uiConstants.DARK_PURPLE,
            },
            marginBottom: "30px",
            marginTop: "2rem",
          }}
        >
          Let's Go
        </Button> */}
      </form>
      <Typography variant="body2" className="subtitle" sx={{ color: "white" }}>
        Already have an account?{" "}
        <Button
          variant="text"
          className="link"
          onClick={handleLogin}
          sx={{ color: "#d81b60", textTransform: "none" }}
        >
        Click here
        </Button>
      </Typography>
    </Box>
    </GoogleReCaptchaProvider>
  );
};

export default EmailSignUpPage;

import { Box, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

function CheckOutBulletListItem({ description}) {
  return (
    
    <Box
    sx={{ display: "flex", alignItems: "flex-start", marginBottom: "10px" }}
  >
    <CheckCircle color="success" />
    <Typography sx={{ marginLeft: "10px", textAlign: "left" }}>
      { description }
    </Typography>
  </Box>
  )
}

export default CheckOutBulletListItem
import axios from "axios";
import { API_URL } from "../../utils/constants";

export const getUserCards = async (userId, langCode) => {
    try {
      const response = await axios.get(`${API_URL}/usercard/user/${userId}`, {
        params: { lang_code: langCode },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error.response.data;
    }
  };


  export const getUserSampleCards = async (userId, langCode) => {
    try {
      const response = await axios.get(`${API_URL}/usercard/user/${userId}/sam`, {
        params: { lang_code: langCode },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error.response.data;
    }
  };


export const addDeckToUser = async ({userId, deckCode}) => {

  const requestBody ={
    userId, 
    deckCode
  }

  try {
    const response = await axios.post(`${API_URL}/userdeck/buy`, requestBody);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error.response.data;
  }
};



export const getAllCards = async (deckType, langCode) => {

  const requestBody = {
    card_type: deckType, 
    lang_code: langCode,
    target_s3_path: "https://virtuecards.s3.amazonaws.com/processed/packages"
  }

  try {
    const response = await axios.get(`${API_URL}/cards?card_type=${requestBody.card_type}&lang_code=${requestBody.lang_code}&target_s3_path=${requestBody.target_s3_path}`);
    return response.data.cards
    
  } catch (error) {
    
  }

}

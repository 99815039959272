import axios from "axios";
import { API_URL } from "../../utils/constants";
import { v1 as uuidv1 } from "uuid";


export const getEntries = async (userId) => {
    try {
        const response = await axios.get(`${API_URL}/journals?userId=${userId}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error.response.data;
    }
}



export const addEntry = async ( {userId, cardId, journalEntry, backgroundColor}) => {
    const requestBody = {
        journalId: uuidv1(),
        userId,
        cardId,
        journalEntry,
        backgroundColor,
        whoAdded: userId
    }
    try {
        const response = await axios.post(`${API_URL}/journal`, requestBody);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error.response.data;
    }
}


export const editEntry = async ({journalId, userId, journalEntry, backgroundColor}) => {
    const requestBody = {
        journalEntry,
        backgroundColor,
        whoUpdated: userId
    }
    try {
        const response = await axios.put(`${API_URL}/journal/${journalId}`, requestBody);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error.response.data;
    }
}


export const deleteEntry = async (journalId) => {
    try {
        const response = await axios.delete(`${API_URL}/journal/${journalId}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error.response.data;
    }
}
import { Box, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import SectionTitle from "../general/SectionTitle";
import CardGridItem from "./CardGridItem";
import { openCard } from "../../features/ui/uiSlice";
import { setSelectedCard } from "../../features/cards/cardsSlice";
import { CiCirclePlus } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { uiConstants } from "../../utils";
const CardsGrid = ({ deckProperties, isShowMore, deckCode }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isListCards } = useSelector((state) => state.uiState);
  const { isCompactCards } = useSelector((state) => state.uiState);
  const cards = useSelector((state) => state.cardsState.actualDeck);

  const handleClick = (card) => {
    dispatch(setSelectedCard(card));
    dispatch(openCard());
  };
  const handleShowMore = () => {
    navigate("/app/shop-checkout/" + deckCode);
  };

  return (
    <>
      <SectionTitle
        textColor={deckProperties.color}
        titleText={deckProperties.title}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflow: "scroll",
          height: "70vh",
          alignSelf: "stretch",
          justifySelf: "stretch",
          paddingBottom: "10px",
          margin: "10px 0 100px",
          scrollbarWidth: "none",
          scrollBehavior: "smooth",
          borderRadius: "8px",
        }}
        id="deckscards"
      >
        {cards.map((card, index) => (
          <CardGridItem
            isListCards={isListCards}
            isCompactCards={isCompactCards}
            key={card.card_id}
            deckProperties={deckProperties}
            card={card}
            handleCardClick={handleClick}
            cardsLength={cards.length}
            index={index}
          />
        ))}

        {isShowMore && (
          <Box
            sx={{
              minHeight: "50px",
              width: "95%",
              margin: "auto",
              backgroundColor: deckProperties.color,
              marginTop: "20px",
              borderRadius: "20px",
              display: "flex",
              gap: "10px",
              boxShadow: "0 0px 10px rgba(0, 0, 0, 0.4)",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              transition: "all 0.3s ease",
              "&:hover": { backgroundColor: uiConstants.BLUE ?? "#e0e0e0", transform: "scale(0.9)" },
            }}
            onClick={handleShowMore}
          >
            <Typography sx={{ color: "white", fontSize: "18px" }}>
              Preview and purchase full deck
            </Typography>
            <CiCirclePlus
              style={{
                fontSize: "1.5rem",
                display: "block",
                fontWeight: "bold",
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default CardsGrid;

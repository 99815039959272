import axios from "axios";
import { API_URL } from "../../utils/constants";

const localURL = "http://localhost:5000/api";

export const verifyRecaptcha = async (token) => {
    const requestBody = { token };
    try {
      const response = await axios.post(`${API_URL}/auth/verify-recaptcha`, requestBody);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error.response.data;
    }
  };

import {  DECK_PURCHASE_CARD_TITLE } from "../utils";

export const filterCardsByTitle = (cards, searchParam) => {
  return cards.filter((card) => {
    const title = card.title.toLowerCase();
    const searchParamLower = searchParam.toLowerCase();
    return title.includes(searchParamLower);
  });
};

export const getDecksName = (decks) => {
  if (decks.length === 0) {
    return [];
  }
  return decks.map((deck) => DECK_PURCHASE_CARD_TITLE[deck.deck_code]);
};

export const filterByDeck = (cardList) => {
  const filteredCards = {
    REF: [],
    SUN: [],
    FAM: [],
    EDU: [],
    CHA: [],
    DEC: [],
  };

  cardList.forEach((card) => {
    filteredCards[card.card_type].push(card);
  });
  return filteredCards;
};

export const getDeckFromTitle = (decks, selectedDeckTitle) => {
  const key = Object.keys(DECK_PURCHASE_CARD_TITLE).find(
    (k) => DECK_PURCHASE_CARD_TITLE[k] === selectedDeckTitle
  );
  return decks[key] || [];
};

export const getNextcards = (actualCard, cards) => {
  const selectedCardIndex = cards.findIndex(
    (card) => card.card_id === actualCard.card_id
  );
  const randomIndex = Math.floor(Math.random() * cards.length);

  const randomCard = cards[randomIndex];
  const previousCard =
    selectedCardIndex > 0 ? cards[selectedCardIndex - 1] : null;
  const nextCard =
    selectedCardIndex < cards.length - 1 ? cards[selectedCardIndex + 1] : null;
  return { randomCard, previousCard, nextCard };
};


export const trimArrays = (obj, num) => {

  let newObj = {};

  for (let key in obj) {
      if (Array.isArray(obj[key])) {  
          newObj[key] = obj[key].slice(0, num);
      } else {
          newObj[key] = obj[key];
      }
  }

  return newObj;
}
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Snackbar,
  IconButton,
  Typography,
  Link,
  Box,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import vp_card from "../assets/images/vp_cards.png";
import { signIn } from "../logic/Requests";
import { useDispatch } from "react-redux";
import { loginUser } from "../features/user/userSlice";
import { redirect } from "react-router-dom";
import { uiConstants, recaptchaKey } from "../utils/constants";
import { resetUiState } from "../features/ui/uiSlice";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ReCaptchaComponent from "../components/general/ReCaptcha";

export const loader = (store) => async () => {
  const user = store.getState().userState.user;

  if (user) {
    return redirect("/app/home");
  }
  return null;
};

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(resetUiState());

  const handleBack = () => {
    navigate(-1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const response = await signIn(email, password);
      if (+response.status.code !== 0) {
        setError(
          error.message || "Invalid email or password. Please try again."
        );
        setOpenSnackbar(true);
        return;
      }

      const { token, user_id, first_name, last_name } = response.user;
      dispatch(loginUser({ token, user_id, first_name, last_name, email }));
      navigate("/app/home");
    } catch (error) {
      console.error(error);
      setError(error.message || "Invalid email or password. Please try again.");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const textFieldStyles = {
    "& .MuiInput-underline:before": { borderBottomColor: "white" },
    "& .MuiInput-underline:after": { borderBottomColor: "white" },
    "& .MuiInputBase-input": { color: "white" },
    "& .MuiInputLabel-root": { color: "white" },
    width: "100%",
    maxWidth: "100%",
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
      <Box
        className="container"
        sx={{
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          padding: "10px 30px 30px",
        }}
      >
        <IconButton
          className="back-button"
          onClick={handleBack}
          sx={{ color: "white", alignSelf: "flex-start", marginBottom: "20px" }}
        >
          <ArrowBackIcon />
        </IconButton>
        <img src={vp_card} alt="Virtues Cards Logo" className="logo" />
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            onClose={handleCloseSnackbar}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </MuiAlert>
        </Snackbar>
        <Box
          component="form"
          className="form"
          onSubmit={handleSubmit}
          sx={{ width: "100%", maxWidth: "400px", marginTop: "4rem" }}
        >
          <TextField
            type="email"
            label="Email"
            variant="standard"
            className="input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            fullWidth
            margin="normal"
            sx={textFieldStyles}
          />
          <TextField
            type={showPassword ? "text" : "password"} // Toggle visibility based on state
            label="Password"
            variant="standard"
            className="input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            fullWidth
            margin="normal"
            sx={textFieldStyles}
            InputProps={{ // Add toggle button to password input
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  edge="end"
                  sx={{ color: "white"}}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              ),
            }}
          />
          <Box
            className="forgot-password"
            sx={{ textAlign: "right", marginBottom: "20px", marginTop: "10px" }}
          >
            <Link
              href="/forgot-password"
              className="link"
              sx={{ color: "white" }}
            >
              Forgot Password
            </Link>
          </Box>

          <ReCaptchaComponent
            btnComponent={
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="button"
                fullWidth
                sx={{
                  width: "218px",
                  margin: "auto",
                  borderRadius: "20px",
                  backgroundColor: uiConstants.PURPLE, // Pink color
                  color: "white",
                  "&:hover": {
                    backgroundColor: uiConstants.DARK_PURPLE, // Darker pink on hover
                  },
                  marginBottom: "30px",
                }}
              >
                Log In
              </Button>
            }
          />
        </Box>
        <Typography
          className="subtitle"
          sx={{ color: "white", marginTop: "20px" }}
        >
          Create an Account{" "}
          <Link
            href="/signup"
            className="link"
            sx={{ color: "#e91e63", textTransform: "none" }}
          >
            Click here
          </Link>
        </Typography>
      </Box>
    </GoogleReCaptchaProvider>
  );
};

export default LoginPage;
